import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CodingCourseDetails.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./PHPDev.css";
import "./ScratchProgramming.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import pic1 from "./CodingCourseImages/php.png";
import pic2 from "./CodingCourseImages/phpArray.png";
import pic3 from "./CodingCourseImages/sqlJoin.png";
import pic4 from "./CodingCourseImages/phpFunc.png";
import pic5 from "./CodingCourseImages/embededPhp.png";
import pic6 from "./CodingCourseImages/filingPhp.png";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import PHPD from './CodingCourseImages/phpdev.png'

const PHPDev = ({selectedCourse}) => {
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
   
  return (
    <div >
      
     {/* Navbar */}
     {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
     
      <div className="PHPdev-Background">
        <section className="section">
          <p className="PageHead"style={{ color: "#8b0194" }}>PHP Procedural Course</p>
          <div className="PageDesc">
          <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
            An extensive course for the ambitious youngsters to learn the art of developing professional looking websites with dynamic content. Polymath-Kids offer a PHP Basic course for Grade 4 to 6 and a PHP Procedure course for Grade 7+. Students will learn to develop Algorithmic Thinking required to create an interactive website, creating and handling database, creating login and registration systems and much more…
            </p>
            <img style={{minWidth:"250px", maxWidth:"450px"}} src={PHPD}></img>
            </div>
            <div className="btn-row d-flex btn-row-resp btn-row-resp1">
              {/* <div className="btn-row-resp"> */}
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className="btn-row-resp button btn-color-course"
                  href="#course"
                >
                  Course Structure
                </a>
              </div>

              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-fee" href="#fee">
                  Fee Structure
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-Testimonal"
                  href="#testimonal"
                >
                  Testimonals
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                  Faqs
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-registered"
                  href="#"
                >
                  Get Registered
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="overlay"></div>
      </div>

      {/* Intro text */}
      <div className="scratch-background">
        <section className="section">
          <p className="PageHead" style={{ color: "#8b0194" }}>
          What is PHP?
          </p>
          <div className="PageDesc">
            <p
              style={{
                color: "#8b0194",
                marginTop: "2vh",
                fontSize: "4vh",
                fontFamily: "sans serif",
              }}
            >
             We live in a world where information is generated instantly through various websites. Kids are using a variety of websites such as Google, YouTube and gaming sites for educational and entertainment purposes. Knowing how these websites are created and maintained can be beneficial for kids in a variety of ways.

            Polymath-Kids offer two courses for Web Development for kids, to help them create fun and informative websites. The two courses offered are Web Development Beginner Course and Responsive Web Development Course.

            Students in Web Development Beginner Course will learn how to create their own simple Web page. There are 5 billion internet users around the globe, but only a small percentage of them can create their own websites. Polymath-Kids help kids to create and manage their own websites with the help of trained Expert Instructors. Polymath-Kids' Web Development Beginner Course is suitable for students of Grade 1 to 3, where they will learn the basics of HTML and CSS to create a simple Webpage.

            Responsive Web Development course is suitable for students, Grade 4 and above, interested in designing their own websites. Students in Web Interfaces learn how to create stunning, interactive websites utilizing a variety of tools and techniques such as HTML, CSS and Java Scripts. They will learn different techniques to customize, organize, and style their web pages through engaging projects. By the end of the course, students will have a strong grip over web development in three fundamental programming languages HTML, CSS and Java Scripts.
            </p>
          </div>
        </section>
      </div>

      {/* Course Structure and Syllabus */}
      <section className="section course-structure" id="course">
        <p className="PageHead" style={{ color: "#8b0194" }}>
          Course Structure and Syllabus
        </p>
        <h4 className="H4_Heading" style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Course Objectives</b>
        </h4>
        <ul style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <li>Creating professional website with dynamic content</li>
          <li>Developing Algorithmic Thinking</li>
          <li>Introduction to MySQLi  </li>
          <li>Tools to Read From & Write Data to Databases</li>
          <li>Solving Math Problems</li>
          <li> Displaying Current Dates & Times </li>
          <li> Handling Form Data</li>
          <li> Sending & Receiving Data</li>
          <li> Creating/Modifying Images</li>
          <li>Learn Controlling user access</li>
          <li>Creating GUI interface using PHP concepts</li>
        </ul>

        <div>
          <p className='moduleSection'>
            <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
            PHP Basic Course  - Suitable For grade 4 to 6
          </p>
        </div>

        {/* Module one */}
        <div className="modules">
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              1
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>13</p>
            </p>

            <div
              className="small-box sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
            PHP Basics
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                The students must have basic knowledge of HTML and CSS to join this course. This course will introduce young kids with the backend of Web Development. They will have an opportunity to work with the server and learn concepts such as variables, data types, types of conditions and loops, creating a database and uploading data to a database.
              </p>
            </p>
          </div>
        </div>
        {/* Module one */}
        <div>
          <p className='moduleSection' style={{color: "#485cd9",}}>
            <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
            PHP Procedural Course - Suitable For grades 7 onwards
          </p>
        </div>

        <div className="modules">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              1
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>13</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>7</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Fundamentals of PHP
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                This module will help students learn the backend of Web Development. They will have the opportunity to work with the server and learn the concepts such as variables, data types and different types of conditions and loops.
              </p>
            </p>
          </div>
        </div>

        {/* Module two */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              2
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>11</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Embed PHP with HTML
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
               In this module, students will learn how to embed PHP with HTML. They will also learn about arrays and functions along with different methods of PHP, file handling, reading data from files and writing data to files. 
              </p>
            </p>
          </div>
        </div>

        {/* Module three */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              3
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>9</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>5</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Introduction to MySQL 
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                Students will be introduced with MySQLi Database. They will learn how to create a database and how to upload data in a database. They will also learn about different queries like INSERT, UPDATE, and DELETE.
              </p>
            </p>
          </div>
        </div>

          {/* Module four */}
        <div className="modules my-3">
          {/* round box */}
          <div className="round-box round-box1 ">
            <p style={{ marginTop: "5vh", color: "#d9defb" }}>
              {" "}
              Module
              <br />
              4
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box small-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>11</p>
            </p>

            <div
              className="small-box small-box1 sm-box"
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
              </p>
            </div>
          </div>

          <div className="large-box large-box1" style={{ color: "#485cd9" }}>
            <p style={{ margin: "auto" }}>
            Advanced PHP Concepts 
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
               In this module, students will work with the advanced concepts of PHP. They will learn how to upload and download the file from the website. They will also learn how to create the login and registration systems, how to change passwords and the concepts of sessions. 
              </p>
            </p>
          </div>
        </div>       

        <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Learn PHP with 40+ Fun Activities & Projects</b>
        </h6>

        <div className="activityImagesContainer">
          <div class="row">
            <div class="col-lg mx-3">
              <img src={pic2} alt="" className='ActivityImages'/>
              <p className="mx-3">FM Radio App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic3} alt="" className='ActivityImages'/>
              <p className="mx-3">Light Bulb App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic4} alt="" className='ActivityImages'/>
              <p className="mx-3">Walking Step App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic5} alt="" className='ActivityImages'/>
              <p className="mx-3">Animal Sound App</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic6} alt="" className='ActivityImages'/>
              <p className="mx-3">Dictionary</p>
            </div>

          </div>
        </div>
      </section>

      {/* Fee Structure */}
      <FeeStructure subject="Coding"/>

      {/* Testimonals */}
      <div class="  background" id="testimonal">
        <div class="row" style={{ width: "100%" }}>
          <div class="col-lg-8 mx-auto">
            <h2 className="Testimonal_Heading">Testimonials</h2>
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
              {/* <!-- Carousel indicators --> */}
              <ol class="carousel-indicators">
                <li
                  data-target="#myCarousel"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
              </ol>
              {/* <!-- Wrapper for carousel items --> */}
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="img-box">
                    <div className=" img-box1"></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I enjoyed the learning experience with EduTechSage-Abacus.
                    The teacher was very friendly. I highly recommend
                    EduTechSage-Abacus.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Mujtaba Khawaja Sahaf</b>
                  </p>
                </div>

                <div class="carousel-item">
                  <div class="img-box">
                    <div className="img-box2 "></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I feel so proud when my class fellows call me ’BRAINIAC ’,
                    because I can effortlessly do mental calculations much
                    faster and accurate than my class mates. Thank you
                    Polymath-Kids.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Wahaab Omer</b>, Age 9 Years
                  </p>
                </div>
              </div>
              {/* <!-- Carousel controls --> */}
              <a
                class="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev"
              >
                <i class="fa fa-angle-left"></i>
              </a>
              <a
                class="carousel-control-next"
                href="#myCarousel"
                data-slide="next"
              >
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
      <div style={{ maxWidth: "100%", backgroundColor: "#edffff" }} id="Faq">
        <MDBContainer className="mt-0" style={{ maxWidth: "180vh" }}>
          <MDBAccordion alwaysOpen initialActive={1}>
            <div className="text-center PageHead" style={{ color: "#8b0194" }}>
              {" "}
              FAQs
            </div>
            <MDBAccordionItem
              collapseId={1}
              headerTitle="Q: Why learn PHP at an early age?"
            >
              <strong>A: </strong>
              The research related to human brain establishes that in order to enhance mental activities in children, it is crucial to activate human brain in the formative years. Activating the brain means developing the brain muscles by practicing mental activities and lifetime skills. Research indicates that the mental development achieved by a child before the age of 12 is crucial as the mental abilities developed before this age has a substantial influence on the child. Learning a beneficial skill such as developing dynamic websites can help with whole brain development of the child.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="Q: Why should kids learn PHP?"
            >
              <strong>A: </strong>
             
              Learning to develop dynamic websites is an important skill and can help kids generate income in their free time or holidays. 
What are the benefits of advantages of PHP ?
PHP is widely used scripting language and has a range of benefits which contributes to its popularity 
<li>

	PHP is platform-independent. It runs on various platforms such as Windows, Linux, Unix and Mac OS X 
</li>
<li>

	PHP is compatible with almost all servers used today (Apache, IIS, etc.)
</li>
<li>

	PHP supports a wide range of databases. It can easily be connected to popular databases such as MySQL, MongoDB, Postgress and many others.
</li>
<li>

	PHP open source and is free to use. Anyone can get the original code and build upon it. You can download it from the official PHP resource: www.php.net
</li>
<li>
	PHP is easy to learn and runs competently on the server side
</li>
<li>

	PHP has a supportive online community
</li>
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Who Uses PHP?"
            >
              <strong>A: </strong>
              A number of big giant companies use PHP to run their servers and create a difference on global level. Some of the big companies using PHP include  
            <li>

            	Facebook
            </li>
            <li>

            	Wikipedia
            </li>
            <li>

            	Content Management Systems such as Drupal, Joomla, and Magento
            </li>
            <li>

            	Shopify and Etsy
            </li>
            <li>

            	Web Hosting Platforms such as BlueHost, Site ground, and Whogohost 
            </li>
            <li>

            	Tumblr
            </li>
            <li>

            	Slack
            </li>
            <li>

            	MailChimp
            </li>
            </MDBAccordionItem>

            
           
          </MDBAccordion>
        </MDBContainer>
      </div>
      
{/* Footer */}
<Footer/>

    </div>
  )
}

export default PHPDev
