import React,{useContext, useRef} from 'react'

import StudentNav from '../../Navbars/StudentNav'
import AdminNav from '../../Navbars/AdminNav'
import TeacherNav from '../../Navbars/TeacherNav';
import GuestNav from '../../Navbars/GuestNav';
import { UserContext } from '../../UserContext';
import Footer from '../../Footer'
import './ActivityStartPage.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

import { useLocation } from "react-router-dom";

import axios from 'axios';

const ActivityStartPage = () => {


  // const selectedCourse = ""
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activityId = searchParams.get("activityId");
  const activityType = searchParams.get("activityType");

  console.log("Activity ID:", activityId);
  console.log("Activity Type:", activityType);
        
    const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);

    const [activityStudentData, setActivityStudentData] = useState("");
    const [studentName, setStudentName] = useState("");

    const [totalQuestions, setTotalQuestions] = useState(0);
    

    useEffect(() => {
      // Define an async function to fetch the questions from the API
      async function fetchQuestions() {
        try {
          const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityQuestions/${activityId}`);
          
          console.log(response.data[0]);
          
          var total_questions = 0;
          response.data.forEach(data => {
              total_questions++;
          });
          setTotalQuestions(total_questions);

        } catch (error) {
          console.error(error);
        }
      }
  
      // Call the async function to fetch the questions
      fetchQuestions();
    }, [activityId]);
    
    var userId = useRef(null);

    const RetrieveData = async (userId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityStudentDetails/${activityId}/${userId}`);

        console.log("Activity Detail Response:", response.data);

        setActivityStudentData(response.data)

      } catch (error) {
        console.error(error);
      }
    };

    const RetrieveStudentName = async (userId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getUser/${userId}`);

        console.log("User Details Response:", response.data.user);

        setStudentName(response.data.user.firstName)

      } catch (error) {
        console.error(error);
      }
    };
     
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            userId = userInfo.User_id;

            console.log("UserInfo: Id", userId)
            RetrieveData(userId);
            RetrieveStudentName(userId);
            
            

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;



      // useEffect(() => {
      //   // Define an async function to fetch the questions from the API
      //   async function fetchActivityDetails() {
      //     try {
      //       const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getActivityStudentDetails/${activityId}/${3}`);

      //       console.log("Activity Detail Response:", response);
           
  
      //     } catch (error) {
      //       console.error(error);
      //     }
      //   }
    
      //   // Call the async function to fetch the questions
      //   fetchActivityDetails();
      // });

      const handleStartClick = () => {
        let route = "";

          if (activityType === "MCQActivity") {
            route = "/MCQActivity";
          } else if (activityType === "WrittenActivity") {
            route = "/WrittenActivity";
          } else if (activityType === "VideoActivity") {
            route = "/VideoActivity";
          } else if (activityType === "SpeedQuizActivity") {
            route = "/SpeedQuizActivity";
          } else if (activityType === "MentalActivity") {
            route = "/MentalActivity";
          } else if (activityType === "OralActivity") {
            route = "/OralActivity";
          }
          window.open(`${route}?activityId=${activityId}`, "_blank");
      };

  return (
    <div>
       {/* Navbar */}
    {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
       )}  
       {!username && (
         <>
         {isGuest && <GuestNav onLogin={onLogin} />}

         </>
       )}
        <div className='edit-bar'>
        <h3 className='student-ActivityStartPage'>
            {studentName} -- {activityStudentData.moduleName} - {activityStudentData.lessonName} - {activityStudentData.activityName}
            {/* Ahmad Jalal -- {activityStudentData.courseName} - {activityStudentData.moduleName} - {activityStudentData.lessonName} - {activityStudentData.activityName} */}
            {/* Ahmad Jalal you are on Module 5t - Scratch M5 Lesson 1 - Scratch M5 L1 Activity 2 */}
        </h3>
      </div>
        <div className='StartActivityContainer'>
            <div className='ActivityStartDetails'>
                <h4>Activity Name:  </h4>
                <h6 style={{marginLeft:"5px", marginTop:"5px"}}> {activityStudentData.activityName}</h6>
            </div>
            <div className='ActivityStartDetails'>
                <h4>Activity Type: </h4>
                <h6 style={{marginLeft:"5px", marginTop:"5px"}}> {activityType}</h6>
                {/* <p>Oral</p> */}
            </div>
            <div className='ActivityStartDetails'>
                <h4>No. of Questions:   </h4>
                <h6 style={{marginLeft:"5px", marginTop:"5px"}}> {totalQuestions}</h6>
                {/* <p>{totalQuestions}</p> */}
            </div>
            <Link
              to=""
              className="AttendClassjoinBtn"
              onClick={handleStartClick}
            >
              Start
            </Link>

        </div>


       {/* Footer */}
       <Footer/>
    </div>
  )
}

export default ActivityStartPage
