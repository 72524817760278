import React from 'react';
import { useState,useEffect } from 'react';
import Logo from '../CourseImages/Logo.png';
import './Nav.css';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import  instance  from '../ManageContent/managecontentforms/axiosInstance';
import ResetPwPopup from '../ResetPassword/ResetPwPopup';

const GuestNav = ({ onLogin, onSignup }) => {
  const [popup, setPop] = useState(false);
  const [signup, setSignUp] = useState(false);

  const [countries, setCountries] = useState([]);
  const [fileError, setFileError] = useState('');

  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [parentName, setParentName] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [address, setAddress] = useState('');
  const [schoolCode, setSchoolCode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [loginAfterSignup, setLoginAfterSignup] = useState(false);

  const [values,setValues]=useState({
    usertype:'Student',
    signupEmail:'',
    signupUsername:'',
    signupPassword:'',
    confirmPassword:'',
    firstName:'',
    lastName:'',
    gender:'',
    dateOfBirth:'',
    parentName:'',
    whatsappNumber:'',
    address:'',
    schoolCode:'',
    deactivated:0,
    selectedCountry:'',
    selectedFile:'',
})
const [loginValues,setLoginValues]=useState({
  loginUsername:'',
  loginPassword:''
})


//for Login Input handling
const handleLoginInput=(event)=>{
  setLoginValues(prev =>({...prev,[event.target.name]:[event.target.value]}))
}
//for signup Input handling
const handleInput=(event)=>{
    setValues(prev =>({...prev,[event.target.name]:[event.target.value]}))
}
useEffect(() => {
  if (loginAfterSignup) {
    handleLogin2();
  }
}, [loginAfterSignup]);

const handleSubmit = async (event) => {
  event.preventDefault();
  if (!selectedFile){
    toast.error('Image is required', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      style: { zIndex: 10000 },
    });
    return
  }
  // Check if the username is unique
  axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/checkUniqueUsername`, { username: values.signupUsername })
    .then((response) => {
      if (response.data.isUnique) {
        // Username is unique, proceed with password check
         // Check if passwords are not empty
         if (values.signupPassword.length === 0 || values.confirmPassword.length === 0) {
          toast.error('Password fields cannot be empty.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            style: { zIndex: 10000 },
          });
          return;
        }
        // Check if passwords match
          if (String(values.signupPassword).trim().toLowerCase() !== String(values.confirmPassword).trim().toLowerCase()) {
            toast.error('Password does not match with confirm password.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              style: { zIndex: 10000 },
            });
            return;
          }
          // Create a FormData object to send the data, including the selected image
    const formData = new FormData();
    formData.append("selectedFile", selectedFile);
    formData.append("signupUsername", values.signupUsername);
    formData.append("usertype", values.usertype);
    formData.append("signupEmail", values.signupEmail);
    formData.append("signupPassword", values.signupPassword);
    formData.append("confirmPassword", values.confirmPassword);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("gender", values.gender);
    formData.append("dateOfBirth", values.dateOfBirth);
    formData.append("parentName", values.parentName);
    formData.append("whatsappNumber", values.whatsappNumber);
    formData.append("address", values.address);
    formData.append("schoolCode", values.schoolCode);
    formData.append("deactivated", values.deactivated);
    formData.append("selectedCountry", values.selectedCountry);

    
    
        axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/register`,formData,{mode:'no-cors'})
          .then((res) => {
            setSignUp(false);
            const insertId = res.data.insertId;
            const nestedValues = {
              user_id: insertId,
              // pointsearned: 0,
              // pointsredeemed: 0
            };
            // console.log('res.data',res.data)
            axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/registerInStudentTable`, nestedValues)
              .then(() => {
                toast.success('Successfully Signup', {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                });
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => {
            toast.error('Signup Unsuccessful!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              style: { zIndex: 10000 },
            });
          });
        document.body.style.overflow = 'auto'; // Allow scrolling
        setLoginAfterSignup(true);
        setLoginValues({
          loginUsername: values.signupUsername,
          loginPassword: values.signupPassword,
        });
      } else {
        // Username is not unique, show an error message
        toast.error('Username already exists. Please choose a different username.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          style: { zIndex: 10000 },
        });
      }
    })
    .catch((err) => {
      console.error('Error checking username uniqueness:', err);
    });
};

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      const allowedExtensions = ['image/png', 'image/jpeg', 'image/jpg'];

      if (allowedExtensions.includes(fileType)) {
        setSelectedFile(file);
        // console.log('file:',file);
        setValues(prev =>({...prev,[event.target.name]:[event.target.files[0]]}));
        // console.log("IN handlefile change",selectedFile);
        setFileError('');
      } else {
        setSelectedFile(null);
        setFileError('Please upload a .png, .jpeg, or .jpg file.');
      }
    }
  };
  axios.defaults.withCredentials=true;
  const navigate=useNavigate();
  const handleLogin2 = async () => {
    // event.preventDefault();
    // console.log('loginvlaues:',loginValues)
    var U_type;
    // console.log('loginValues:::',loginValues);
    await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/login`,loginValues)
    .then(res=>
        {
          if(res.data.Login){
            U_type=res.data.usertype;
            setPop(false);
          }
        })
    .catch(err=>console.log("Error:",err))
    // console.log("U_type just before:",U_type)
    onLogin(U_type);
  };

  const [loading, setLoading] = useState(false);
  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading state to true when login is initiated

    try {
      const res = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/login`, loginValues);

      console.log('res.data.Login:',res.data);
      if (res.data.Login) {
        const U_type = res.data.usertype;
        setPop(false);
        toast.success('Successfully Login', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        document.body.style.overflow = 'auto'; // Allow scrolling
        onLogin(U_type);
      } else {
        toast.error('Incorrect username or password!', {
          // toast.error('Credentials are incorrect or You might have been deactivated!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          style: { zIndex: 10000 },
        });
      }
      setLoginValues({
        loginUsername: '',
        loginPassword: ''
      });
    } catch (err) {
      console.log("Error:", err);
    } finally {
      setLoading(false); // Set loading state back to false regardless of success or failure
    }
  };

  const signUp = () => {
    setSignUp(!signup);
    setLoginValues({
      loginUsername: '',
      loginPassword: ''
    });

    fetch('https://restcountries.com/v3.1/all')
      .then((response) => response.json())
      .then((data) => {
        const countryList = data.map((country) => ({
          code: country.cca2,
          name: country.name.common,
        }));
        const sortedCountries = countryList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
  };

  const closeSignUp = () => {
    setSignUp(false);
    setPop(false);

  };

  const handleClickOpen = () => {
    setPop(!popup);
    document.body.style.overflow = 'hidden'; // Prevent scrolling
  };

  const closePopup = () => {
    setPop(false);
    document.body.style.overflow = 'auto'; // Allow scrolling
  };
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setPop(false);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };


  return (
    <>
      <div style={{ width: '100%' }}>
        <nav class="navbar navbar-expand-lg navbar-light " style={{ backgroundColor: '#edffff' }}>
          <div class="container-fluid">
            <img src={Logo} alt="Logo" className="pageLogo"/>

            <button
              style={{ marginLeft: 'auto' }}
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse mx-3 Navbar-Right-Aligned" id="navbarSupportedContent">
              <ul class="navbar-nav" style={{ marginLeft: '10%' }}>
                <li class="nav-item active  ">
                  <Link class="nav-link navLinks" to="/">
                    Home
                    <span class="sr-only">(current)</span>
                  </Link>
                </li>
                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/polymatch">
                    Polymatch
                  </Link>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle navLinks"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Life Skill Courses
                  </a>
                  <div
                    class="dropdown-menu"
                    style={{ border: '1px  solid #03b4c6', backgroundColor: '#edffff' }}
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link class="dropdown-item UserDropdown" to="/abacus_home">
                      Math Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/coding_home">
                    Coding Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/business_portal">
                     Business Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/quran_portal">
                      Quran Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/graphic_designing_portal">
                      Graphic Designing Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/engineering_portal">
                      Engineering Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/space_sc_portal">
                      Space Sciences Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/arch_interior_design">
                      Architecture Portal
                    </Link>
                    <Link class="dropdown-item UserDropdown" to="/comm_skills_portal">
                      Communication Skills Portal
                    </Link>
                  </div>
                </li>

                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/polymathkids_at_schools">
                    Polymath-kids @ Schools
                  </Link>
                </li>
                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/our_team">
                    About Us
                  </Link>
                </li>
                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/contact_us">
                    Contact Us
                  </Link>
                </li>
                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/faqs">
                    FAQs
                  </Link>
                </li>

                <li class="nav-item ">
                  <a class="nav-link navLinks" href="#" onClick={handleClickOpen}>
                    Sign In
                  </a>
                  <div>
                    {popup ? (
                      <div className="main">
                        <form action="" onSubmit={handleLogin}>
                          <div className="modal-overlay" ></div>
                        <div className={`popup ${popup ? 'overlay1122  ' : ''}`}>
                          <div className="popup-header">
                            <h5 style={{ padding: '8% 4% 4% 4%' }}>Log in to Polymath-Kids.com</h5>
                            <h3 onClick={closePopup} style={{ cursor: 'pointer' }}>
                              x
                            </h3>
                          </div>

                          <input
                            type="text"
                            placeholder="Enter Username"
                            className='inp'
                            name='loginUsername'
                            required
                            value={loginValues.loginUsername}
                            onChange={handleLoginInput}

                          />

                          <input
                            type="password"
                            placeholder="Enter Password"
                            name='loginPassword'
                            className='inp'
                            required
                            value={loginValues.loginPassword}
                            onChange={handleLoginInput}
                          />

                          <br />
                          <div style={{ display:"flex",padding:"10px",alignItems:"center" }}>
                            <input type="checkbox" style={{width:"20px",marginRight:"20px",marginBottom:"0"}}/>
                            <div>Remember me</div>
                            
                          </div>
                          <br />
                          <button className="btn" disabled={loading}>
                            {loading ? 'Logging in...' : 'Log in'} {/* Disable button and show loading text if loading state is true */}
                          </button>
                          <br />
                          <button className="btn" onClick={signUp}>
                            Sign up
                          </button>
                          <br />
                          <a  className="resetlink" style={{color: '#03b4c6', 'margin-left': '30%', cursor:'pointer' }}  onClick={handleOpenDialog}>
                            Reset Password
                          </a>
                          
                        </div>
                        </form>
                      </div>
                    ) : (
                      ''
                    )}
                    {isDialogOpen && (
                      <ResetPwPopup isOpen={isDialogOpen} onClose={handleCloseDialog}
                     />
                    )}
                  </div>
                  <div>
                    {signup ? (
                      <form action="" onSubmit={handleSubmit}>
                      <div className="main">
                        <div className={`popup ${signup ? 'overlay1133' : ''}`}>
                          <div className="popup-header">
                            <h5 style={{ padding: '8% 2% 2% 2%' }}>Sign Up to Polymath-Kids.com</h5>
                            <h3 onClick={closeSignUp} style={{ cursor: 'pointer' }}>
                              x
                            </h3>
                          </div>

                          <input type="email" name='signupEmail'  required onChange={handleInput} placeholder="Email" className="inp" />
                          <input type="text" name='signupUsername'  required onChange={handleInput} placeholder="Username" className="inp" />
                          <input type="password" name='signupPassword' required onChange={handleInput} placeholder="Password" className="inp" />
                          <input type="password" name='confirmPassword' required onChange={handleInput} placeholder="Confirm Password" className="inp" />
                          <input type="text" name='firstName' required onChange={handleInput} placeholder="First Name" className="inp" />
                          <input type="text" name='lastName' required onChange={handleInput} placeholder="Last Name" className="inp" />
                          <select name="gender" className="inp" required onChange={handleInput}>
                          <option value="">Select gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>

                          </select>

                          <input type="date" name='dateOfBirth' required onChange={handleInput} placeholder="Date of Birth" className="inp" />
                          <input type="text" name='parentName' required onChange={handleInput} placeholder="Parent name" className="inp" />
                          <input type="text" name='whatsappNumber' required onChange={handleInput} placeholder="WhatsApp Number" className="inp" />
                          <input type="text" name='address' required onChange={handleInput} placeholder="Address" className="inp" />
                          <input type="number" name='schoolCode' onChange={handleInput} placeholder="School Code" className="inp" />

                          <select name="selectedCountry" required className="inp" onChange={handleInput}>
                            <option value=''>Choose country</option>
                            {countries.map((country) => (
                              <option key={country.code} value={country.code}>
                                {country.name}
                              </option>
                            ))}
                          </select>

                          <br />
                          <label htmlFor="avatar-upload" required className="SignUP-avatar-upload">
                            {selectedFile ? (
                              <img src={URL.createObjectURL(selectedFile)} alt="Avatar" />
                            ) : (
                              'Upload avatar'
                            )}
                            <input
                              type="file"
                              id="avatar-upload"
                              accept=".png, .jpeg, .jpg"
                              onChange={(e)=>{handleFileChange(e)}}
                              className="Signupbtn"//;handleInput(e)
                              name='selectedFile'
                              style={{display:"none"}}
                              // required
                            />
                          </label>
                          {fileError && <p>{fileError}</p>}

                          <p>
                            By signing up, I agree to the <br />
                            <Link to="/TermsOfUse" className="link">
                              Terms of Service
                            </Link>{' '}
                            and{' '}
                            <Link to="/Policy" className="link">
                              Privacy Policy
                            </Link>
                          </p>
                          <button className="btn" style={{ marginLeft: '10%', padding: '3% 24% 3% 25%' }} >
                            Sign Up
                          </button>
                        </div>
                      </div>
                      </form>

                    ) : (
                      ''
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default GuestNav;
