import React, { useState, useRef, useEffect } from "react";
import "./managecontentstyle/AddActivityQuestion.css";
import axiosInstance from "./axiosInstance";
import { toast } from "react-toastify";
import "./managecontentstyle/buttonstyle.css";
import { FaEdit, FaTrash, FaCopy, FaPlus } from "react-icons/fa";
import EditActivityQuestion from "./EditActivityQuestion";

const QuestionCard = ({ question, handleDelete }) => {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const modalRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeEditModal();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  return (
    <div className="mb-4">
      <div
      className="activity-card"
      key={question.Question_id}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div className="question-info" style={{ marginLeft: "5px" }}>
      <div className="question-info" style={{ marginLeft: "5px" }}>
        <div>Q: {question.question}</div>
        <div>A: {question.correctanswer}</div>
        <div>Difficulty Level: {question.difficultylevel}</div>
        <div>Marks: {question.marks}</div>
        <div>Timer: {question.timealloted}</div>
        {question.option1 && <div>Option1: {question.option1}</div>}
        {question.option2 && <div>Option2: {question.option2}</div>}
        {question.option3 && <div>Option3: {question.option3}</div>}
        {question.option4 && <div>Option4: {question.option4}</div>}
        <div>Instruction: {question.instructions}</div>
      </div>
      </div>

      <div
        className="icons-container"
        style={{ flex: "0 0 30%", cursor: "pointer" }}
      >
        <FaEdit className="edit-icon" onClick={openEditModal} />
        <FaTrash
          className="delete-icon"
          onClick={() => handleDelete(question.Question_id)}
        />
      </div>

      {isEditModalOpen && (
        <div className="" ref={modalRef}>
          <div className="">
            <EditActivityQuestion
              questionob={question}
              handleClose={closeEditModal}

            />
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

function AddActivityQuestion({ activityID, handleClose }) {
  const [formData, setFormData] = useState({
    activityId: activityID,
    question: "",
    answer: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    timeAllowed: "",
    voiceRecording: null,
    fileUploaded: null,
    difficultyLevel: "",
    marks: "",
    instruction: "",
  });

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchLessonActivities();
  }, []);

  const fetchLessonActivities = () => {
    axiosInstance
      .post("/getSpecificActivityQuestions", { activityID })
      .then((response) => {
        setQuestions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });
    axiosInstance
      .post("/api/managecourse/addActivityQuestion", formDataToSend)
      .then((response) => {
        toast.success("Lesson Activity Question has been added", {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClose();
        fetchLessonActivities();

      })
      .catch((error) => {
        toast.error(`Issue in adding Lesson Activity Question: ${error}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleDelete = (questionID) => {
    setQuestions(
      questions.filter(
        (question) => question.Question_id !== questionID
      )
    );
    const payload = { Question_id: questionID };
    axiosInstance
      .post("/api/managecourse/deleteLessonQuestion", payload)
      .then(() => {
        toast.success("Lesson Activity  Question Deleted", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log("Internal server error", error);
      });
  };

  const handleEdit = (questionID) => {
    const questionToEdit = questions.find(
      (question) => question.Question_id === questionID
    );
  };


  return (
    <div className="add-question-dialog">
      <div className="card mb-5">
        <div className="card-body maintain-overflow-question">
          {questions.map((question) => (
            <QuestionCard
              key={question.Question_id}
              question={question}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ))}
        </div>
      </div>

      <h4 className="inz-title">Add Activity Question</h4>
      <form onSubmit={handleSubmit}>
        <div className="form-field">
          <label className="label">Question:</label>
          <input
            type="text"
            name="question"
            value={formData.question}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-field">
          <label className="label">Answer:</label>
          <input
            type="text"
            name="answer"
            value={formData.answer}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-field">
          <label className="label">Difficulty Level:</label>
          <select
            name="difficultyLevel"
            value={formData.difficultyLevel}
            className="inz-dropdown"
            onChange={handleChange}
            required
          >
            <option value="">Select Difficulty Level</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>

        <div className="form-field">
          <label className="label">Marks:</label>
          <input
            type="number"
            min="0"
            name="marks"
            className="input-question"
            value={formData.marks}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-field">
          <label className="label">Instruction:</label>
          <input
            name="instruction"
            className="input-question"
            value={formData.instruction}
            onChange={handleChange}

          ></input>
        </div>

        <div className="form-field">
          <label className="label">Option 1:</label>
          <input
            type="text"
            name="option1"
            value={formData.option1}
            onChange={handleChange}

          />
        </div>

        <div className="form-field">
          <label className="label">Option 2:</label>
          <input
            className="input-question"
            type="text"
            name="option2"
            value={formData.option2}
            onChange={handleChange}

          />
        </div>

        <div className="form-field">
          <label className="label">Option 3:</label>
          <input
            className="input-question"
            type="text"
            name="option3"
            value={formData.option3}
            onChange={handleChange}

          />
        </div>

        <div className="form-field">
          <label className="label">Option 4:</label>
          <input
            className="input-question"
            type="text"
            name="option4"
            value={formData.option4}
            onChange={handleChange}

          />
        </div>

        <div className="form-field">
          <label className="label">Time Allowed:</label>
          <input
            className="input-question"
            type="number"
            min="0"
            name="timeAllowed"
            value={formData.timeAllowed}
            onChange={handleChange}

          />
        </div>

        <div className="form-field">
          <label className="label">Voice Recording Uploaded:</label>
          <input
            className="input-question"
            type="file"
            name="voiceRecording"
            onChange={handleChange}
          />
        </div>

        <div className="form-field">
          <label className="label">File Uploaded:</label>
          <input
            className="input-question"
            type="file"
            name="fileUploaded"
            onChange={handleChange}
          />
        </div>

        <div className="inz-button-row">
          <button type="submit" className="inz-save-button">
            Save
          </button>
          <button
            type="button"
            className="inz-deletesp-button"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddActivityQuestion;
