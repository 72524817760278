import React,{useContext,useEffect} from "react";

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav' 
import GuestNav from "../Navbars/GuestNav";

import { UserContext } from '../UserContext';
import Footer from '../Footer'
import "./AdobeXD.css";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import pic1 from "./GraphicCourseImages/adobeXD.png";
import pic2 from "./GraphicCourseImages/uiFundamentals.png";
import pic3 from "./GraphicCourseImages/animation.png";
import pic4 from "./GraphicCourseImages/shapes.png";
import pic5 from "./GraphicCourseImages/logoDesign.png";
import pic6 from "./GraphicCourseImages/businessCard.png";
import TeacherNav from "../Navbars/TeacherNav";
import FeeStructure from "../components/FeeStructure";
import XD from './GraphicCourseImages/adobeBackground.png'

const AdobeXD = ({selectedCourse}) => {
  
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
   
  return (
    <div >
      
     {/* Navbar */}
     {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
     


      <div className="AdobePortalBackground">
        <section className="section">
          <p className="PageHead" style={{ color: "#8b0194" }}>Adobe XD for Beginners </p>
          <div className="PageDesc">
          <div style={{display:"flex"}}>
            <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
            Learn to create beautiful stories, posters, slides, assets, animations and much more using an easy to use, powerful graphic design platform. Polymath-Kids offer a dynamic graphic design course- Adobe XD for Beginners, for kids Grade 4 onwards to help them get creative while learning real world skills. In this course, students will learn the fundamentals of UI/UX and graphic designing using Adobe XD platform to craft the finest designs for their projects. Learning to design at an early age provides an opportunity for kids to develop artistry, creativity, problem solving, business and branding skills. This course is designed specially to help kids go beyond learning and designing professional projects in real time. 
            </p>
            <img style={{minWidth:"150px", maxWidth:"350px"}} src={XD}></img>
            </div>
            <div className="btn-row d-flex btn-row-resp btn-row-resp1">
              {/* <div className="btn-row-resp"> */}
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className="btn-row-resp button btn-color-course"
                  href="#course"
                >
                  Course Structure
                </a>
              </div>

              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-fee" href="#fee">
                  Fee Structure
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-Testimonal"
                  href="#testimonal"
                >
                  Testimonals
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-Faq" href="#Faq">
                  Faqs
                </a>
              </div>
              <div style={{ width: "auto", height: "50px" }}>
                <a
                  className=" btn-row-resp button btn-color-registered"
                  href="#"
                >
                  Get Registered
                </a>
              </div>
            </div>
          </div>
        </section>
        <div className="overlay"></div>
      </div>

      {/* Intro text */}
      <div className="scratch-background">
        <section className="section">
          <p className="PageHead" style={{ color: "#8b0194" }}>
          What does Polymath-Kids Adobe XD course Offers?
          </p>
          <div className="PageDesc">
            <p
              style={{
                color: "#8b0194",
                marginTop: "2vh",
                fontSize: "4vh",
                fontFamily: "sans serif",
              }}
            >
              Graphic designing is the practical art of communicating your message to the audience using visual elements such as images, symbols, colors, typography (letter design). Polymath-Kids Adobe XD for Beginner Course help kids to create their own designs by exploring a wide range of fonts, texts, effects, elements and templates to create cards, posters, Web banners, animations, website designs, mobile app designs and much more.  They will learn to share their creations with teachers and friends online.
            </p>
          </div>
        </section>
      </div>

      {/* Course Structure and Syllabus */}
      <section className="section course-structure" id="course">
        <p className="PageHead" style={{ color: "#8b0194" }}>
          Course Structure and Syllabus
        </p>
        <h4 className="H4_Heading" style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Course Objectives</b>
        </h4>
        <ul style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
            <li>
                     Fundamentals of UI/UX design 
            </li>
            <li>
	                Adobe XD platform basic and advanced
            </li>
            <li>
  	                Design using Adobe XD Assets and Animations
            </li>
            <li>
                	Creating Brochures and Flyers
            </li>
            <li>
                  	Logo design

            </li>
            <li>
                    Website and Mobile App design
            </li>
            <li>
            	    Web Banner design
            </li>
        </ul>

        <div>
          <p className='moduleSection'>
            <img src={pic1} alt="" style={{ width: "2em", height: "2em" }} />
            Graphic Designing in Adobe XD
          </p>
        </div>

        {/* Module one */}
        <div className="modules">
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              1
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>11</p>
            </p>

            <div
              className="small-box sm-box" 
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
            UI/UX Design Basic Adobe XD
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
               Students will learn the fundamentals of UI/UX design. They will also learn to use assets and components to create animations in Adobe XD.
              </p>
            </p>
          </div>
        </div>

        {/* Module  two */}
        <div className="modules" style={{ marginTop: "2vh" }}>
          {/* round box */}
          <div className="round-box">
            <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
              {" "}
              Module
              <br />
              2
            </p>
          </div>
          {/* small boxes */}
          <div className="small-box" style={{ color: "#249935" }}>
            <p style={{ margin: "auto" }}>
              Activities
              <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>11</p>
            </p>

            <div
              className="small-box sm-box" 
            >
              <p style={{ margin: "auto" }}>
                No.of Lessons
                <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>6</p>
              </p>
            </div>
          </div>

          <div className="large-box" style={{ color: "#249935" }}>
            <p className="my-4" style={{ margin: "auto" }}>
            UI/UX Design Advance Adobe XD
              <p
                style={{
                  fontWeight: "lighter",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                In this module students will learn how to create different designs in Adobe XD. They will create different websites and App designs. They will also acquire the knowledge required to create the logo and flyer Design.
              </p>
            </p>
          </div>
        </div>

       

        <h6 style={{ color: "#8b0194", fontFamily: "sans-serif" }}>
          <b>Learn Graphic Designing in Adobe XD with 20+ Fun Activities</b>
        </h6>

        <div className="activityImagesContainer">
          <div className="row">
            <div className="col-lg mx-3">
              <img src={pic2} alt="" className='ActivityImages'/>
              <p className="mx-3">UI/UX Fundamentals</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic3} alt="" className='ActivityImages'/>
              <p className="mx-3">Animations</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic4} alt="" className='ActivityImages'/>
              <p className="mx-3">Shapes</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic5} alt="" className='ActivityImages'/>
              <p className="mx-3">Logo Designing</p>
            </div>

            <div class="col-lg mx-3">
              <img src={pic6} alt="" style={{width:"23vh",height:"23vh"}}/>
              <p className="mx-3">Business Cards</p>
            </div>

          </div>
        </div>
      </section>

      {/* Fee Structure */}
      <FeeStructure subject="AdobeXD"/>

      {/* Testimonals */}
      <div class="  background" id="testimonal">
        <div class="row" style={{ width: "100%" }}>
          <div class="col-lg-8 mx-auto">
            <h2 className="Testimonal_Heading">Testimonials</h2>
            <div id="myCarousel" class="carousel slide" data-ride="carousel">
              {/* <!-- Carousel indicators --> */}
              <ol class="carousel-indicators">
                <li
                  data-target="#myCarousel"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li data-target="#myCarousel" data-slide-to="1"></li>
              </ol>
              {/* <!-- Wrapper for carousel items --> */}
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="img-box">
                    <div className=" img-box1"></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I enjoyed the learning experience with EduTechSage-Abacus.
                    The teacher was very friendly. I highly recommend
                    EduTechSage-Abacus.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Mujtaba Khawaja Sahaf</b>
                  </p>
                </div>

                <div class="carousel-item">
                  <div class="img-box">
                    <div className="img-box2 "></div>
                  </div>
                  <p
                    class="testimonial"
                    style={{ color: "black", fontSize: "3vh" }}
                  >
                    I feel so proud when my class fellows call me ’BRAINIAC ’,
                    because I can effortlessly do mental calculations much
                    faster and accurate than my class mates. Thank you
                    Polymath-Kids.
                  </p>
                  <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                    <b>Wahaab Omer</b>, Age 9 Years
                  </p>
                </div>
              </div>
              {/* <!-- Carousel controls --> */}
              <a
                class="carousel-control-prev"
                href="#myCarousel"
                data-slide="prev"
              >
                <i class="fa fa-angle-left"></i>
              </a>
              <a
                class="carousel-control-next"
                href="#myCarousel"
                data-slide="next"
              >
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Faqs */}
      <div style={{ maxWidth: "100%", backgroundColor: "#edffff" }} id="Faq">
        <MDBContainer className="mt-0" style={{ maxWidth: "180vh" }}>
          <MDBAccordion alwaysOpen initialActive={1}>
            <div className="text-center PageHead" style={{ color: "#8b0194" }}>
              {" "}
              FAQs
            </div>
            <MDBAccordionItem
              collapseId={1}
              headerTitle="Q: Can kids actually learn graphic design? "
            >
              <strong>A: </strong>
              Yes, kids can learn graphic design by learning the main concepts of design theory and getting started with the basics of graphic design software appropriate for their age. In the age of Instagram stories, memes and YouTube videos, kids are eager to create their own graphics. Polymath-Kids offer graphic designing courses for all age groups to help them unlock their creativity and design artistic content.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="Q: What is the difference between UI/ UX?"
            >
              <strong>A: </strong>
              UI and UX are two different but related concepts. UI, or “user interface,” is what the user sees on a web page or screen. This includes buttons location, length or paragraphs on a page, and color schemes, along with other things. These elements are important to leave a lasting impact on users.

                UX, or “user experience,” is about how a user interacts with the page or application. This is less about what the page looks like and more about how the page is used. A UX designer will decide how a website is navigated, and their final goal is to make the navigation and use of the site as convenient as possible. There is some overlap between these two forms of design, but they require different skills and ways of thinking.
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Why is it important to teach design concepts to kids?"
            >
              <strong>A: </strong>
              Teaching design concepts to kids is not just about teaching them how to make things look beautiful. Graphic Designing skills help to teach creative ways of thinking, which is essential in today’s world. Any person can learn the answer to a question and repeat it back, but innovation requires creative minds that can see solutions not perceived by others. 

Learning design is a step forward toward developing a creative mind, as a creative endeavor is, in some ways similar to a puzzle. There’s an idea in your mind, and you’re trying to solve the puzzle of how to bring your idea to others in the form of images and fonts to communicate the whole story. This level of focus improves a child’s problem-solving skills and creativity.
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What is graphic designing?"
            >
              <strong>A: </strong>
              Graphic design is the art and practice of planning and projecting ideas and experiences using visual elements and textual content. These visual elements include images, symbols, typography (letter design) and colors, used to convey certain messages and information in an appealing and attractive manner. Graphic designing is also termed as Visual Communications.
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Why is graphic design important for kids?"
            >
              <strong>A: </strong>
              Kids are naturally interested in colors and drawings. Graphic Designing is like a puzzle that needs creativity and problem solving skills to come up with an attractive and appealing visual. Learning to design at an early age provides an opportunity for kids to develop artistry, creativity, problem solving, business and branding skills.
            </MDBAccordionItem>

            <MDBAccordionItem
             collapseId={3}
             headerTitle="Q: How will Graphic Designing benefit Kids?"
           >
             <strong>A: </strong>
             Polymath-Kids Graphic design courses enable kids to
             <li>
	Design their very own business card and logo or design for others
             </li>
             <li>
	Design book covers
             </li>
             <li>
	Create impeccable school and personal projects
             </li>
             <li>
	Learn a variety of design skills and tools
             </li>
             <li>
	Create attractive CVs
             </li>
             <li>
	Design personalized social media avatars and stickers
             </li>
             <li>
	Learn to draw their favorite characters and get it printed on t-shirts or mugs
             </li>
             
             <li>
	Earn online through graphic designing skill
             </li>
           </MDBAccordionItem>
          
           <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Can kids do graphic designing?"
            >
              <strong>A: </strong>
              Yes, a kid can be an excellent graphic designer! To be a graphic designer you need to get inspiration from the world around you. Kids have the natural capability to see the world in an innovative way, which help them relate their ideas in a creative manner and bring to life using graphic designing tools.
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: What age can you start graphic design?"
            >
              <strong>A: </strong>
              Graphic design is not a field limited to adults. Anyone having a passion for color, design or sketching can learn graphic design. The availability of easy to use platform makes it possible for kids as young as 6 years to start learning graphic designing, with the help of trained instructors. Polymath-Kids offer a range of graphic design courses suitable for ages 6 onwards. Our well trained instructors ensure to keep the classes lively and colorful to help kids achieve maximum benefit.
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: How do kids get into graphic design?"
            >
              <strong>A: </strong>
              Kids can take Polymath-Kids structured graphic Design classes designed specifically for children aged 6 onwards. Our well trained instructors ensure to keep the classes lively and colorful to help kids achieve maximum benefit.
            </MDBAccordionItem>

            <MDBAccordionItem
              collapseId={3}
              headerTitle="Q: Which is the best graphic designing course for kids?"
            >
              <strong>A: </strong>
              Polymath-Kids offer the best graphic design courses for kids aged 6 onwards. Our comprehensive courses are specifically designed for kids to ensure they grasp the concepts in the live classes with expert instructors. Polymath-Kids offers graphic design courses for kids in featured packed, powerful platforms such as Canva, Adobe XD, Photoshop, Adobe Illustrator and Corel Draw. 
            </MDBAccordionItem>

         </MDBAccordion>
        </MDBContainer>
      </div>
      
{/* Footer */}
<Footer/>

    </div>
  )
}

export default AdobeXD
