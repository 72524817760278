import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'


// import { useParams } from "react-router-dom";

import { useLocation } from "react-router-dom";

import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import axios from 'axios';

import { useRef, useState, useEffect } from 'react'



import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// Renders errors or successfull transactions on the screen.
function Message({ content }) {
  return <p>{content}</p>;
}
 

const MakePaymentPage = () => {

  const selectedCourse = ""
  
    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
          navigate("/");

        }
          
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
        const usertype = userInfo?.usertype;   
        
        


        
  const [invoiceData, setInvoiceData] = useState(null);
  const [amountDue, setAmountDue] = useState(null);

  const getInvoiceData = async (feeId) => {
    console.log("I am innn...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/studentInvoiceDetails/${feeId}`
      );
      console.log("InvoiceData is: ", response.data);
      setInvoiceData(response.data);

      setAmountDue(response.data.amountdue)

    } catch (error) {
      console.error("Error retrieving InvoiceData:", error);
    }
  };


  const markPaymentDone = async (feeId) => {
    console.log("I am innn...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_SERVER_API}/markPaymentDone/${feeId}/1`
      );

    } catch (error) {
      console.error("Error retrieving InvoiceData:", error);
    }
  };



        const location = useLocation();

        const invoiceNumber_ = useRef(0);
        
        const urlParams = new URLSearchParams(location.search);
        const feeId = urlParams.get('fee_id');
      
        useEffect(() => {
          // Parse the query parameters from the URL
          
          const invoiceNumber = urlParams.get('invoiceNumber');
      
          invoiceNumber_.current = invoiceNumber;

          getInvoiceData(feeId);
          // Fetch the payment data from an API or database
         
        }, [location.search]);

        const [message, setMessage] = useState("");

        const initialOptions = {
            "client-id": "ARAT0gePt6fZ6jQQNlNTFlCg7mwjbuIAhDBQf0tWIu2N00znaWFWMptC3omSEmuT5atS_xFtupkdFO1F",
            currency: "USD",
            "data-page-type": "product-details",
            components: "buttons",
            "data-sdk-integration-source": "developer-studio",
          };

    

  return (


    <div>

{username && (
        <>
             {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
            {isTeacher && <TeacherNav  onCourseSelect={handleCourseSelect}  username={username} avatar={_avatar_} onLogout={onLogout}/>}
            {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
         </>
         )}  
         {!username && (
           <>
           {isGuest && <GuestNav onLogin={onLogin} />}
  
           </>
         )}

    {invoiceData && 

    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
        backgroundColor: '#fff',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          padding: '32px',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          width: '80%',
          maxWidth: '600px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '32px',
          }}
        >
          <h1 style={{ margin: 0, fontSize: '24px', fontWeight: 'bold' }}>INVOICE</h1>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Invoice No: {invoiceNumber_.current}
          </span>
        </div>
        <div style={{ marginBottom: '16px' }}>
          <p style={{ margin: '8px 0', fontSize: '16px' }}>
            <strong>Child Name:</strong> <b>{invoiceData.username}</b>
          </p>
          <p style={{ margin: '8px 0', fontSize: '16px' }}>
            <strong>Course Name:</strong> <b>{invoiceData.coursename}</b>
          </p>
          <p style={{ margin: '8px 0', fontSize: '16px' }}>
            <strong>Invoice Date:</strong> {invoiceData.generationdate}
          </p>
          <p style={{ margin: '8px 0', fontSize: '16px' }}>
            <strong>Due Date:</strong> {invoiceData.duedate}
          </p>
          <p style={{ margin: '8px 0', fontSize: '16px' }}>
            <strong>Amount: ${invoiceData.amountdue}</strong>
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '48%',
            }}
          >
            <p style={{ fontSize: '16px' }}>
              <strong>Billed To:</strong>
            </p>
            <p style={{ fontSize: '16px' }}>
              {invoiceData.parentname}
            </p>
            <p style={{fontSize: '16px' }}>
              {invoiceData.address}
            </p>
            <p style={{ fontSize: '16px' }}>
              {invoiceData.phonenumber}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '48%',
            }}
          >
            <p style={{ margin: '8px 0', fontSize: '16px' }}>
              <strong>From:</strong>
            </p>
            <p style={{ fontSize: '16px' }}>
            Polymath-Kids.com
            </p>
            <p style={{ fontSize: '16px' }}>
            0326669907
            </p>
            <p style={{  fontSize: '16px' }}>
            info@polymath-kids.com
            </p>
          </div>
        </div>
        {/* Add payment processing components here */}
      </div>
    </div>
}

{/* <div style={{marginLeft: "40vh"}}>
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
            color: "gold",
            label: "paypal",
            height: 48,
            tagline: false,
            shape: "pill"
          }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units:[
                {
                  description: "Course Payment",
                  amount:{
                    value: amountDue
                  }
                }
              ]
            })
            
          }}
          onApprove={async (data,actions) => {
            const order = await actions.order.capture();
            console.log("Order: ", order);

            markPaymentDone(feeId);
          }} 
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div> */}

    <Footer/>

    </div>
  )
}

export default MakePaymentPage;


