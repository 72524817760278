import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import './BusinessCourseDetails.css'
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import pic1 from './BusinessCourseImages/kidpreneur.png'
import pic2 from './BusinessCourseImages/digitalMarketing.png'
import pic3 from './BusinessCourseImages/funFinance.png'
import pic4 from './BusinessCourseImages/eCommerce.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import BCD from './BusinessCourseImages/businessBackground.png'


const BusinessCourseDetails = () => {
 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
  return (
    <div >
      
     {/* Navbar */}
     {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}

       <div className="BusinessPortalBackground">
          <section className="section">
            <p className="PageHead" style={{ color: "#8b0194" }}>Business Portal</p>
            <div className="PageDesc">
            <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
              Encouraging kids to think of creative ways of doing things mean helping them come up with great business ideas. Polymath-Kids Business courses are especially designed for kids and teens (aged 9 to 16) to equip them with the knowledge required to navigate stages of Entrepreneurship with confidence. The benefits of being able to start a small venture at a young age are far beyond financial. It helps kids to gain Critical 21st Century Life Skills that impact their emotional wellbeing by enhancing their creativity, critical thinking, research skills, self-direction, planning, confidence and leadership skills. 
              </p>
              <img style={{minWidth:"250px", maxWidth:"450px"}} src={BCD}></img>
              </div>
              <div className="btn-row d-flex btn-row-resp btn-row-resp1">

                {/* <div className="btn-row-resp"> */}
                <div style={{ width: "auto", height: "50px" }}>


                  <a className="btn-row-resp button btn-color-course" href="#course">Individual Courses</a>
                </div>
                
                
                <div style={{ width: "auto", height: "50px" }}>

                  <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
                </div>
                <div style={{ width: "auto", height: "50px" }}>
                  <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


                </div>
                <div style={{ width: "auto", height: "50px" }}>
                <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
                 

                </div>


           
              </div>
            </div>

          </section>
          <div className="overlay"></div>
        </div>
{/* Individual courses */}
<div style={{ backgroundColor: '#d8f2fb' }} id="course">
          <p className="PageHead mx-auto" style={{ color: "#8b0194" }}>
          Coding Individual Courses
          </p>

          <div className="container"  >

            <MDBRow>
              <MDBCol md='4'>
                <div className=" col-xs-6">
                  <Link to="/business_kidpreneur">
                    <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                      <h3 className="ind-course-title ">KidPreneur</h3>
                  <div>
                        <img src={pic1} alt="" style={{width:"100px",height:"100px"}}/>
                    </div>
                    
                       </div>
                   
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='4'>
                <div className=" col-xs-6">
                  <Link to="#">
                    <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                      <h3 className="ind-course-title ">Digital Marketing
                      <b style={{color:"blue"}}>(ComingSoon)</b>
                      </h3>
                  <div>
                        <img src={pic2} alt="" style={{width:"100px",height:"100px"}}/>
                    </div>
                    
                       </div>
                   
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='4'>
                <div className=" col-xs-6">
                  <Link to="#">
                    <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                      <h3 className="ind-course-title ">Fun Finance
                      <b style={{color:"blue"}}>(ComingSoon)</b>
                      </h3>
                  <div>
                        <img src={pic3} alt="" style={{width:"100px",height:"100px"}}/>
                    </div>
                    
                       </div>
                   
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md='4'>
                <div className=" col-xs-6">
                  <Link to="#">
                    <div className='ind-course-border d-flex' style={{ paddingTop: "1em" }}>

                      <h3 className="ind-course-title ">ECommerce
                      <b style={{color:"blue"}}>(ComingSoon)</b>                      
                      </h3>
                  <div>
                        <img src={pic4} alt="" style={{width:"100px",height:"100px"}}/>
                    </div>
                    
                       </div>
                   
                  </Link>
                </div>
              </MDBCol>
            
             
           
            </MDBRow>
          </div>


        </div>


    
        {/* Testimonals */}
        <div class="  background" id="testimonal">
          <div class="row" style={{ width: "100%" }}>
            <div class="col-lg-8 mx-auto">
              <h2 className="Testimonal_Heading">Testimonials</h2>
              <div id="myCarousel" class="carousel slide" data-ride="carousel">
                {/* <!-- Carousel indicators --> */}
                <ol class="carousel-indicators">
                  <li
                    data-target="#myCarousel"
                    data-slide-to="0"
                    class="active"
                  ></li>
                  <li data-target="#myCarousel" data-slide-to="1"></li>
                </ol>
                {/* <!-- Wrapper for carousel items --> */}
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="img-box">
                      <div className=" img-box1"></div>
                    </div>
                    <p
                      class="testimonial"
                      style={{ color: "black", fontSize: "3vh" }}
                    >
                      I enjoyed the learning experience with EduTechSage-Abacus.
                      The teacher was very friendly. I highly recommend
                      EduTechSage-Abacus.
                    </p>
                    <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                      <b>Mujtaba Khawaja Sahaf</b>
                    </p>
                  </div>

                  <div class="carousel-item">
                    <div class="img-box">
                      <div className="img-box2 "></div>
                    </div>
                    <p
                      class="testimonial"
                      style={{ color: "black", fontSize: "3vh" }}
                    >
                      I feel so proud when my class fellows call me ’BRAINIAC ’,
                      because I can effortlessly do mental calculations much
                      faster and accurate than my class mates. Thank you
                      Polymath-Kids.
                    </p>
                    <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                      <b>Wahaab Omer</b>, Age 9 Years
                    </p>
                  </div>
                </div>
                {/* <!-- Carousel controls --> */}
                <a
                  class="carousel-control-prev"
                  href="#myCarousel"
                  data-slide="prev"
                >
                  <i class="fa fa-angle-left"></i>
                </a>
                <a
                  class="carousel-control-next"
                  href="#myCarousel"
                  data-slide="next"
                >
                  <i class="fa fa-angle-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Faqs */}
        <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

          <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
            <MDBAccordion alwaysOpen initialActive={1} >
              <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
              <MDBAccordionItem collapseId={1} headerTitle="Q: Why should kids learn about business?" >
                <strong>A: </strong>
                Becoming a young entrepreneur teaches you many important lessons. Starting from how to set and meet goals, solving a problem creatively, and managing money, running a business, critical thinking, and persistence. These skills can help you excel in school and all aspects of life.
               </MDBAccordionItem>
              <MDBAccordionItem collapseId={2} headerTitle="Q: Why is it important for kids to learn about business?">
                <strong>A: </strong>
                Entrepreneurship encourages kids to think critically and help them learn the art of problem solving.

                The whole process helps kids explore and discover their personal strengths and areas for improvement. They learn to align their interests and explore new avenues.
              </MDBAccordionItem>


              <MDBAccordionItem collapseId={3} headerTitle="Q: What is the definition of business for kids?">
                <strong>A: </strong>
                A business offers goods or services for money. A successful business makes profit when it brings in more money than it spends in creating those goods or services. Businesses can be owned by individuals or groups of individuals.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: How can you teach business skills to kids?">
                <strong>A: </strong>
                <li>
                    	Teach Them How to Recognize Opportunities
                </li>
                <li>

                        Let Them Solve Problems
                </li>
                <li>

                            	Inspire Resilience
                </li>
                <li>

                            	Help Them Start Small Business Early in Life
                </li>
                <li>

                            	Teach Financial Literacy
                </li>
                <li>

                            	Teach Goal Setting
                </li>
                <li>

                            	Most Importantly Teach Technology Skills
                </li>
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: What age can a child start a business?">
                <strong>A: </strong>
                A child as young as 9 years can start a small setup. You will have extra challenges ahead of you, because until you're 18 you'll have barriers to setting up your own business bank account, getting credit and raising business finance. But the unlimited opportunities provided by technology opens new horizon for kids wishing to sell their services online. 
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: What life skills does business teach young kids?">
                <strong>A: </strong>
                In addition to learning the basics of business concepts, business management help develop following skills:
                <li>

                Creativity
                </li>
                <li>

                    Networking
                </li>
                <li>

                    Communication and People Skills
                </li>
                <li>

                    Analytical Thinking
                </li>
                <li>

                    Decision Making
                </li>
                <li>

                    Ethical Reasoning
                </li>
                <li>
                
                    Operation and People Management
                </li>
                <li>

                    Problem Solving
                </li>
                <li>

                    Strategy
                </li>
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: Is business a life skill?">
                <strong>A: </strong>
                Undoubtedly, learning to run a business is the most important life skill, as it teaches you the art of continuous improvement. An entrepreneur realizes that one cannot learn everything. No matter how old or young you are, there is always room for improvement and progress.
              </MDBAccordionItem>

              <MDBAccordionItem collapseId={3} headerTitle="Q: Which Business course is best for kids?">
                <strong>A: </strong>
                Polymath-Kids offer a range of best business courses for kids. The courses are designed to nurture young brains and help them grasp the importance of financial independence from young age. Our Business courses along with Coding and Graphic design courses ensure that students have the much needed 21st Century Skills to help them excel in all field of life. 
              </MDBAccordionItem>

             
            </MDBAccordion>
          </MDBContainer>

        </div>

{/* Footer */}
<Footer/>

    </div>
  )
}

export default BusinessCourseDetails
