import React, { useState } from "react";
import { AiOutlineCloudUpload, AiOutlineCheckCircle } from "react-icons/ai";
import "./ActivitiesAndProjects.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ModuleProjects = ({
  title,
  projects,
  backgroundColor,
  textColor,
  iconBackground,
  image,
  courseID,
  activityID,
  uploadApiRoute,
  studentid,
}) => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (e, projectid) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    console.log("File Selected");
    console.log(file)
    handleUpload(file,projectid);
  };

  const handleUpload = async (file,projectID) => {
    console.log("File Uploading");

    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("Student_id", studentid);
    formData.append("Module_Project_id", projectID);

    // console.log("Form Data:");
    // for (const [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    const response = await fetch(
      `${process.env.REACT_APP_LOCAL_SERVER_API}${uploadApiRoute}`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.ok) {
      toast.success("Module Project Uploaded", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSelectedFile(null);
    } else {
      console.error("Error uploading file");
    }
  };


  const handleProjectClick = (projectType, projectID) => {
    // let route = "";
    // window.open(`${route}=${projectID}&studentid=${studentid}`, "_blank");
  };

  return (
    <div className="AP_ModuleProjectLesson" style={{ backgroundColor }}>
      <h2 className="AP_title" style={{ color: textColor }}>
        <img src={image} alt="" style={{ width: "7vh", height: "7vh" }} />{" "}
        {title}
      </h2>
      <ul>
        {projects.map((project, index) => (
          <li key={index} className="AP_Projects">
            <div className="AP_ProjectItem">

              <div style={{maxWidth:"100%", marginBottom:"5px"}}>
              <Link
                to=""
                onClick={() =>
                  handleProjectClick(title, project?.Module_Project_id)
                }
                style={{ marginRight: "7%", color: textColor }}
              >
                {project?.projectassignmentname}
              </Link>
              </div>

              <>
                <label htmlFor={`file-upload-${project?.Module_Project_id}`}>
                  <span
                    style={{
                      color: textColor,
                      backgroundColor: iconBackground,
                    }}
                    className="AP_Icon"
                  >
                    <AiOutlineCloudUpload />
                  </span>
                  <input
                  id={`file-upload-${project?.Module_Project_id}`}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) =>
                    {handleFileSelect(e, project?.Module_Project_id)}
                  }
                />
                </label>



                <span
                  style={{
                    color: textColor,
                    backgroundColor: iconBackground,
                  }}
                  className="AP_Icon"
                >
                  <AiOutlineCheckCircle />
                </span>
                <span
                  style={{
                    color: textColor,
                    backgroundColor: iconBackground,
                  }}
                  className="AP_Score"
                >
                  {project?.MarksObtained}/{project?.TotalMarks} (
                  {(
                    (project?.MarksObtained / project?.TotalMarks) *
                    100
                  ).toFixed(1)}
                  %)
                </span>
              </>
            </div>
            <hr />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ModuleProjects;
