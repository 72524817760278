import React, { useState,useContext,useEffect } from 'react'
import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import './PolymathKidSchool.css';
import './Cancellation.css';
import Footer from '../Footer';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';

const Cancellation = ({selectedCourse}) => {
   
  const handleCourseSelect = (course) => {
    console.log("Selected Course in Home:", course);
    selectedCourse(course);
  };

 const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
 
    useEffect(() => {
      const fetchData = async () => {
       await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
          credentials: 'include',
        }).then(response => {
            console.log("Response:",response);
          response.json().then(userInfo => {
            console.log("userinfo:",userInfo);
            setUserInfo(userInfo);

            if(userInfo?.usertype)
            {
              const uType=userInfo?.usertype;
              setUserRole(uType);
            }
          const uname = userInfo?.username;
          const avatar = userInfo?.avatar; // Store the avatar in a separate variable
          console.log("Uname: ", uname);
          console.log("Avatar: ", avatar);

          })
          .catch(err=> console.log("Error in useeffect:",err))
        });
      }
      fetchData();
    }, [isAdmin, isTeacher, isStudent, isGuest]);
  const determineUserRole = (usertype) => {
        console.log("In home.js:",usertype)
        if(usertype === 'Student')
          return "Student";
        else if(usertype === 'Teacher')
          return "Teacher";
        else if(usertype === 'Admin')
          return "Admin";
        else
          return "guest";
  };
  
  
 const navigate=useNavigate();
  const onLogin = (usertype) => {
    const userRole = determineUserRole(usertype);
    console.log("Calling setUserRole.")
    setUserRole(userRole);
  };

  function onLogout() {
      console.log("inside Onlogout.");
      fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
        credentials: 'include',
        method: 'POST',
      });
      setUserInfo(null);
      setUserRole('guest');
      navigate("/");

    }
      
      const username = userInfo?.username;
      const _avatar_ = userInfo?.avatar;
     
  return (
    <>
    
       {/* Navbar */}
       {username && (
        <>
        {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
        {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
        {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      </>
        )}  
        {!username && (
          <>
          {isGuest && <GuestNav onLogin={onLogin} />}

          </>
        )}
 

    <div className='School' style={{ maxWidth: "100%", backgroundColor: "#e0f7fa" }} >
<div className='innerVision'>
    <h1 style={{color:"black",fontSize:"20px",paddingTop:"4%"}}>  CANCELLATION AND REFUND POLICY</h1>

    <strong>
    At Polymath-Kids, we are passionate about customer service. We understand that plans can change, so please review the following information before registering so you're aware of your commitment to Polymath-Kids.com

    </strong>
     <p className='para'>
     We use class registrations to predict whether or not a session will start, so last-minute student cancellations can affect other students enrolled in the class as well as our instructors who are planning to teach. We've created the following policies to be fair to all parties involved: <br />
     </p>
     <ul>
    <li>

     Online learning classes and camps with paid registration will receive 100% cancellation with at least 7 days’ notice prior to the start of class. A 50% refund is applicable with less than 7 days’ notice, however no refunds will be provided with less than 72 hours notice prior to the start of the class.
    </li>
    <li>
 Our commitment to education and access to computer learning for all, we are always willing to work with parents and students to relocate a class that may not be a good fit - always ask by contacting us and we can work out a time that suits the child.
    </li>
<li>

We will use reasonable efforts to give notice of any cancellation of any courses for unforeseen reasons. If the course cannot be rescheduled, you may be entitled to a refund of any fee paid in respect of the cancelled services but shall not otherwise be entitled to any compensation, costs, losses or damages arising from such cancellation.
</li>

<li>

Polymath-Kids offer NO refunds or make-up sessions for missed group classes. However our instructors may try to help the child with the missed work in the next class, if possible and feasible for the instructor.
</li>
<li>

 These policies constitute the entire agreement between all parties and supersede any previous communications, representations or agreements between parties, whether oral or written, regarding transactions hereunder. Your purchase will constitute your acceptance of these terms which may not be changed except by an amendment signed by an authorized representative of each party.
</li>

<li>

Please contact us if you have any questions through email info@polymath-kids.com or WhatsApp or Call +4747650619.
</li>
     </ul>
</div>
   
  
   </div>
    {/* Footer */}
    <Footer/>
    </>
  )
}

export default Cancellation
