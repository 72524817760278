import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import './CodingCourseDetails.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./Python.css"
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';

import pic1 from './CodingCourseImages/py.png'
import pic2 from './CodingCourseImages/Drawshapes.png'
import pic3 from './CodingCourseImages/calculator.png'
import pic4 from './CodingCourseImages/phonebook.png'
import pic5 from './CodingCourseImages/diceroll.png'
import pic6 from './CodingCourseImages/filehandling.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import pythn from './CodingCourseImages/pythonBackground.png'

const Python = ({selectedCourse}) => {
    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
        }  
        const username = userInfo?.username;
        const _avatar_ = userInfo?.avatar;
  return (
    <div >
    {/* Navbar */}
    {username && (
      <>
      {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
      {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
      {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
    </>
       )}  
       {!username && (
         <>
         {isGuest && <GuestNav onLogin={onLogin} />}

         </>
       )}
 
      <div className="Python-Background">
         <section className="section">
           <p className="PageHead"style={{ color: "#8b0194" }}>Python Programming Course</p>
           <div className="PageDesc">
           <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
             Polymath-kids offer comprehensive online Python programming course, a text-based programming language. The online coding classes’ offers two modes -online 1:1 classes and online group classes with trained teachers having years of experience in Computer Sciences.
             </p>
             <img style={{minWidth:"250px", maxWidth:"450px"}} src={pythn}></img>
             </div>
             <div className="btn-row d-flex btn-row-resp btn-row-resp1">

               {/* <div className="btn-row-resp"> */}
               <div style={{ width: "auto", height: "50px" }}>


                 <a className="btn-row-resp button btn-color-course" href="#course">Course Structure</a>
               </div>
              
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>

               </div>
               <div style={{ width: "auto", height: "50px" }}>

                 <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
               </div>
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


               </div>
               <div style={{ width: "auto", height: "50px" }}>
               <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
                

               </div>


          
             </div>
           </div>

         </section>
         <div className="overlay"></div>
       </div>

        {/* Intro text */}
       <div className='scratch-background'>
       <section className="section">
           <p className="PageHead" style={{ color: "#8b0194" }}>What is Python Programming?</p>
           <div className="PageDesc">
             <p style={{
                 color: "#8b0194",
                 marginTop: "2vh",
                 fontSize: "4vh",
                 fontFamily: "sans serif",
               }}>
              Python is a high-level, general-purpose programming language. Its design philosophy emphasizes code readability with the use of significant indentation. Python is dynamically typed and garbage-collected. It supports multiple programming paradigms, including structured, object-oriented and functional programming. Python is an interpreted, object-oriented, high-level programming language with dynamic semantics. Its high-level built in data structures, combined with dynamic typing and dynamic binding, make it very attractive for Rapid Application Development, as well as for use as a scripting or glue language to connect existing components together. Python's simple, easy to learn syntax emphasizes readability and therefore reduces the cost of program maintenance. <br />
            Python supports modules and packages, which encourages program modularity and code reuse. The Python interpreter and the extensive standard library are available in source or binary form without charge for all major platforms, and can be freely distributed.
               <br />
            Often, programmers fall in love with Python because of the increased productivity it provides. Since there is no compilation step, the edit-test-debug cycle is incredibly fast. Debugging Python programs is easy: a bug or bad input will never cause a segmentation fault. Instead, when the interpreter discovers an error, it raises an exception. When the program doesn't catch the exception, the interpreter prints a stack trace. A source level debugger allows inspection of local and global variables, evaluation of arbitrary expressions, setting breakpoints, stepping through the code a line at a time, and so on. The debugger is written in Python itself, testifying to Python's introspective power. On the other hand, often the quickest way to debug a program is to add a few print statements to the source: the fast edit-test-debug cycle makes this simple approach very effective. <br />

             </p>    
           </div>
         </section>
       </div>
       
       {/* Course Structure and Syllabus */}
       <section className="section course-structure" id="course">
         <p className="PageHead" style={{ color: "#8b0194" }}>
           Course Structure and Syllabus
         </p>
         <h4 className="H4_Heading" style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
               Course Objectives
             </b>
         </h4>
         <ul style={{color:"#8b0194",fontFamily:"sans-serif"}}>
           <li>Learn the expertise of Computer Science using Python Programming:</li>
             <p>          
             {'> '}Variables and data types, such as integers, floats, and strings.<br />
             {'> '}Conditions and operators, such as arithmetic, comparison, and logical operators. <br />
             {'> '}Fundamental building blocks of programming logic, loops, arrays & functions.<br />
             {'> '}Object-oriented programming<br />
             {'> '}Inheritance, Polymorphism, Encapsulation and file handling.<br />
             {'> '}Input function and how to use it to get user input in their programs.<br />
             {'> '}Python's powerful dictionary data structure to store and manipulate key-value pairs<br />
             </p>
         <li>Develop 21st Century Skills using text based Programming:<br /></li>
             <p>          
             {'> '}Logic application and self-expression<br />
             {'> '}Creativity and Problem-Solving Skills<br />
             {'> '}Mathematical, Computational and Analytical Thinking<br />
             {'> '}Persistence<br />
             {'> '}Creativity and Imagination<br />
             {'> '}Digital Literacy<br />
             {'> '}Career-Building Skills<br />
             {'> '}Confidence and Communication Skills<br />
             {'> '}STEM Education<br />
             </p>
         </ul>
         
         <div>
           <p className='moduleSection' >
               <img src={pic1} alt=""style={{width:"2em",height:"2em"}} />
               Python Programming Course
           </p>
         </div>

         {/* Module one */}
         <div className="modules">
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               1
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 11
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   6
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
             Fundamentals of Python
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
               Students will learn the basics of the Python language. They will learn about variables and data types, including integers, floats, and strings. They will also learn about conditions and operators such as arithmetic, comparison, & logical operators. Overall, this module will provide students with a solid foundation in Python programming .
               </p>
             </p>
           </div>
         </div>

         {/* Module  two */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               2
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 11
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Python Logics
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                In this module, students will learn about the fundamental building blocks of programming logic such as loops, arrays, and functions. They will learn how to use loops i.e. for and while to perform repetitive tasks, how to work with arrays to store and manipulate large amounts of data, and how to create reusable code with functions. 
               </p>
              
             </p>
           </div>
         </div>

         {/* Module  Three */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               3
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 11
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Object Oriented Programming
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               > 
                Students will learn about classes and objects, the building blocks of object-oriented programming, and how to use them to organize their code and data. Additionally, students will learn inheritance, Polymorphism and Encapsulation. They will also learn file handling, which enables them to read from and write to files on their computer. 
               </p>
              
             </p>
           </div>
         </div>
         <h6 style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
             Learn Python Programming with 30+ Fun Activities
             </b>
         </h6>

        <div className="activityImagesContainer">
          <div class="row">
            <div class="col-lg mx-3">
                <img src={pic2} alt="" className='ActivityImages'/>
                <p className='mx-3'>Drawing Shapes</p>
            </div>
            <div class="col-lg mx-3">
              <img src={pic3} alt="" className='ActivityImages'/>
              <p className='mx-3'>Calculator</p>
            </div>
            <div class="col-lg mx-3">
              <img src={pic4} alt="" className='ActivityImages'/>
              <p className='mx-3'>Phone Book</p>
            </div>
            <div class="col-lg mx-3">
              <img src={pic5} alt="" className='ActivityImages'/>
              <p className='mx-3'>Dice Roll Simulator</p>
            </div>
            <div class="col-lg mx-3">
              <img src={pic6} alt=""  className='ActivityImages'/>
              <p className='mx-3'>File Handling</p>
            </div>
          </div>
        </div>
      
      

       </section>

        {/* Fee Structure */}
        <FeeStructure subject="Coding"/>

       {/* Testimonals */}
       <div class="  background" id="testimonal">
         <div class="row" style={{ width: "100%" }}>
           <div class="col-lg-8 mx-auto">
             <h2 className="Testimonal_Heading">Testimonials</h2>
             <div id="myCarousel" class="carousel slide" data-ride="carousel">
               {/* <!-- Carousel indicators --> */}
               <ol class="carousel-indicators">
                 <li
                   data-target="#myCarousel"
                   data-slide-to="0"
                   class="active"
                 ></li>
                 <li data-target="#myCarousel" data-slide-to="1"></li>
               </ol>
               {/* <!-- Wrapper for carousel items --> */}
               <div class="carousel-inner">
                 <div class="carousel-item active">
                   <div class="img-box">
                     <div className=" img-box1"></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I enjoyed the learning experience with EduTechSage-Abacus.
                     The teacher was very friendly. I highly recommend
                     EduTechSage-Abacus.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Mujtaba Khawaja Sahaf</b>
                   </p>
                 </div>

                 <div class="carousel-item">
                   <div class="img-box">
                     <div className="img-box2 "></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I feel so proud when my class fellows call me ’BRAINIAC ’,
                     because I can effortlessly do mental calculations much
                     faster and accurate than my class mates. Thank you
                     Polymath-Kids.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Wahaab Omer</b>, Age 9 Years
                   </p>
                 </div>
               </div>
               {/* <!-- Carousel controls --> */}
               <a
                 class="carousel-control-prev"
                 href="#myCarousel"
                 data-slide="prev"
               >
                 <i class="fa fa-angle-left"></i>
               </a>
               <a
                 class="carousel-control-next"
                 href="#myCarousel"
                 data-slide="next"
               >
                 <i class="fa fa-angle-right"></i>
               </a>
             </div>
           </div>
         </div>
       </div>

       {/* Faqs */}
       <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

         <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
           <MDBAccordion alwaysOpen initialActive={1} >
             <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
             <MDBAccordionItem collapseId={1} headerTitle="Q: What is Python programming language, and why should I learn it?" >
               <strong>A: </strong>
               Python is a popular high-level programming language used for a variety of purposes such as web development, data analysis, artificial intelligence, and more.
             </MDBAccordionItem>
             <MDBAccordionItem collapseId={2} headerTitle="Q: Who can take this Python course?">
               <strong>A: </strong>
               Anyone who is interested in learning programming or looking to improve their Python skills can take this course. Basic programming experience in text-based coding like C or C++ is required.
             </MDBAccordionItem>


             <MDBAccordionItem collapseId={3} headerTitle="Q: What will I learn in this Python course?">
               <strong>A: </strong>
               In this Python course, you will learn the fundamentals of Python programming, including variables and data types, operators, lists, loops, arrays, functions, and more. You will also learn about object-oriented programming in Python, including classes, objects, inheritance, and file handling.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="Q: How long is the Python course?">
               <strong>A: </strong>
               The length of the Python course may vary depending on the program you choose. However, most courses typically take 16 weeks to complete.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="Q: What kind of projects can I build after completing this Python course?">
               <strong>A: </strong>
               After completing this Python course, you will be able to build endless projects, and the basic course will provide you with a strong foundation to tackle a wide range of projects.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle=" What are the learning goals of the Python programming course for kids?">
               <strong>A: </strong>
               Polymath-Kids offer Python Programming Course for Ages 12 to 18. By the end of the course, students would have mastered the essential principles of coding such as variables, data types, operators, control structures, functions, classes, templates, and more with the help of several activities and Projects. 
                The course design facilitates the young brains by learning coding along with other important skills such as problem solving, logic, creativity and storytelling. 
             </MDBAccordionItem>
           </MDBAccordion>
         </MDBContainer>

       </div>

        {/* Footer */}
        <Footer/>

   </div>
  )
}

export default Python
