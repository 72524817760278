import React,{useContext} from 'react'

import StudentNav from '../Navbars/StudentNav'
import AdminNav from '../Navbars/AdminNav'
import TeacherNav from '../Navbars/TeacherNav';
import GuestNav from '../Navbars/GuestNav';
import { UserContext } from '../UserContext';
import Footer from '../Footer'
import './CodingCourseDetails.css'
import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "../MathPortal/Slider.css";
import "../MathPortal/MathCourseDetails.css";
import "../MathPortal/MathPortalResponsive.css";
import "./AIDataScience.css"
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import pic1 from './CodingCourseImages/AI.svg'
import pic2 from './CodingCourseImages/datacleaning.png'
import pic3 from './CodingCourseImages/charts.png'
import pic4 from './CodingCourseImages/dataClassification.png'
import pic5 from './CodingCourseImages/dataNormalization.png'
import pic6 from './CodingCourseImages/predictionEval.png'
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import FeeStructure from '../components/FeeStructure';
import AIDS from './CodingCourseImages/AIBackground.jpg'

const AIDataScience = ({selectedCourse}) => {
    const handleCourseSelect = (course) => {
        console.log("Selected Course in Home:", course);
        selectedCourse(course);
      };
    
     const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo,userInfo } = useContext(UserContext);   
     
        useEffect(() => {
          const fetchData = async () => {
           await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
              credentials: 'include',
            }).then(response => {
                console.log("Response:",response);
              response.json().then(userInfo => {
                console.log("userinfo:",userInfo);
                setUserInfo(userInfo);
    
                if(userInfo?.usertype)
                {
                  const uType=userInfo?.usertype;
                  setUserRole(uType);
                }
              const uname = userInfo?.username;
              const avatar = userInfo?.avatar; // Store the avatar in a separate variable
              console.log("Uname: ", uname);
              console.log("Avatar: ", avatar);
    
              })
              .catch(err=> console.log("Error in useeffect:",err))
            });
          }
          fetchData();
        }, [isAdmin, isTeacher, isStudent, isGuest]);
      const determineUserRole = (usertype) => {
            console.log("In home.js:",usertype)
            if(usertype === 'Student')
              return "Student";
            else if(usertype === 'Teacher')
              return "Teacher";
            else if(usertype === 'Admin')
              return "Admin";
            else
              return "guest";
      };
      
      
     const navigate=useNavigate();
      const onLogin = (usertype) => {
        const userRole = determineUserRole(usertype);
        console.log("Calling setUserRole.")
        setUserRole(userRole);
      };
    
      function onLogout() {
          console.log("inside Onlogout.");
          fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
            credentials: 'include',
            method: 'POST',
          });
          setUserInfo(null);
          setUserRole('guest');
          navigate("/");

        }
          
          const username = userInfo?.username;
          const _avatar_ = userInfo?.avatar;
  return (

    <div >
    {/* Navbar */}
    {username && (
       <>
       {isAdmin && <AdminNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
       {isTeacher && <TeacherNav  username={username} avatar={_avatar_} onLogout={onLogout}/>}
       {isStudent && <StudentNav  username={username} avatar={_avatar_} onLogout={onLogout} />}
     </>
       )}  
       {!username && (
         <>
         {isGuest && <GuestNav onLogin={onLogin} />}

         </>
       )}
 
      <div className="AI-Background">
         <section className="section">
           <p className="PageHead" style={{ color: "#8b0194" }}>AI & Data Science Course</p>
           <div className="PageDesc">
           <div style={{display:"flex"}}>
              <p style={{
                fontFamily: "KG Primary Whimsy,VanillaMilk,'DM Sans', sans-serif",
                color: "white",
                textAlign: "left",
                padding: "30px",
                fontSize: "22px",
                zIndex: 1,
                color: "#8b0194",
                width:"70%"
                
              }}>
             Polymath-kids offer comprehensive online AI & Data Science course. The online classes’ offers two modes -online 1:1 classes and online group classes with trained teachers having years of experience in Computer Sciences.
             </p>
             <img style={{minWidth:"250px", maxWidth:"450px"}} src={AIDS}></img>
             </div>
             <div className="btn-row d-flex btn-row-resp btn-row-resp1">

               {/* <div className="btn-row-resp"> */}
               <div style={{ width: "auto", height: "50px" }}>


                 <a className="btn-row-resp button btn-color-course" href="#course">Course Structure</a>
               </div>
              
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-fee" href="#fee">Fee Structure</a>

               </div>
               <div style={{ width: "auto", height: "50px" }}>

                 <a className=" btn-row-resp button btn-color-Testimonal" href="#testimonal">Testimonals</a>
               </div>
               <div style={{ width: "auto", height: "50px" }}>
                 <a className=" btn-row-resp button btn-color-Faq" href="#Faq">Faqs</a>


               </div>
               <div style={{ width: "auto", height: "50px" }}>
               <a className=" btn-row-resp button btn-color-registered" href="#">Get Registered</a> 
                

               </div>


          
             </div>
           </div>

         </section>
         <div className="overlay"></div>
       </div>

        {/* Intro text */}
       <div className='scratch-background'>
       <section className="section">
           <p className="PageHead" style={{ color: "#8b0194" }}>What is AI & Data Science?</p>
           <div className="PageDesc">
             <p style={{
                 color: "#8b0194",
                 marginTop: "2vh",
                 fontSize: "4vh",
                 fontFamily: "sans serif",
               }}>
              Data Engineering, Data Science, and Artificial Intelligence are hot topics in the current digital age. These technologies have changed the way humans interpret a problem. These technologies work on data, but utilize it for different outcomes. Data Science and Artificial Intelligence are technologies that correlate with each other in many ways. Artificial Intelligence in Data Science as a function has taken over technological automation but requires Data Engineering in symphony to function properly. There are constant advancements in the fields of Data Science and Artificial Intelligence and they are said to bring the 4th revolution in the industry. <br />
            The technologies are related to each other in more ways than one. Data Engineering deals with the collection and preparation of data so that it can be used by Artificial Intelligence in Data Science applications. Data Science utilizes this data and predictively and analyzes it to gain insights. Artificial Intelligence deals with working on data by using tools to develop Intelligent systems. Data Science and Artificial Intelligence work on data to produce similar outcomes dealing with analysis.
               <br />
            The main purpose of Artificial Intelligence is to aid human capabilities and predict the far-fetched consequences that the human brain cannot process. Artificial Intelligence has the potential to reduce the hardships of human labor and make a possible pathway for the human species to thrive in a beneficial way. Artificial Intelligence in Data Science has similar purposes.
               <br />
            With development still ongoing in this field the scope of applications increases with every iteration. Artificial Intelligence in Data Science is often used in real-life use. A few prominent applications of Artificial Intelligence are:<br />
            * Personalized Online Shopping <br />
            * Enhanced Imaging and Surveillance  <br />
            * Video Games <br />
            * Healthcare <br />
            * ChatBots <br />
            The main purpose of Data Science is to find patterns in data. It is used to analyze and gain insights using various Statistical Techniques. The present data and historical data are used to predictively analyze future outcomes. These valuable predictions and Insights provide an opportunity for businesses to thrive and adapt based on market trends.
               <br />
            Data Science works on the data and since the quantity of data is growing at a very fast pace, its benefits are also increasing at a good pace. Artificial Intelligence in Data Science methods works prominently on this growing data.
               <br />
            A few prominent applications of Data Science are:  <br />
            * Banking  <br />
            * Manufacturing <br />
            * Transport <br />
            * Healthcare <br />
            * E-Commerce <br />

             </p>    
           </div>
         </section>
       </div>
       
       {/* Course Structure and Syllabus */}
       <section className="section course-structure" id="course">
         <p className="PageHead" style={{ color: "#8b0194" }}>
           Course Structure and Syllabus
         </p>
         <h4 className="H4_Heading" style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
               Course Objectives
             </b>
         </h4>
         <ul style={{color:"#8b0194",fontFamily:"sans-serif"}}>
           <li>Learn the expertise of AI & Data Science using Python Programming:</li>
             <p>          
             {'> '}Data collection & organization, using tables and graphs to make sense of large amounts of data.<br />
             {'> '}Introduce Python, a powerful programming language used extensively in data science. <br />
             {'> '}Learn how to use Python to analyze and visualize data. <br />
             {'> '}Learn different techniques for cleaning and preprocessing data, including imputation and normalization.<br />
             {'> '}Ensure data quality by making it accurate and consistent.<br />
             {'> '}Learn basic statistics & probability concepts such as mean, median, & mode essential for analyzing data. <br />
             {'> '}Understand how these algorithms make predictions and classify data.<br />
             {'> '}Learn how to evaluate the performance of machine learning models.<br />
             {'> '}Students will be able to apply machine learning to real-world problems & make data-driven decisions.<br />
             </p>


         <li>Develop 21st Century Skills using text based Programming:<br /></li>
             <p>          
             {'> '}Logic application and self-expression.<br />
             {'> '}Creativity and Problem-Solving Skills<br />
             {'> '}Mathematical, Computational and Analytical Thinking<br />
             {'> '}Persistence<br />
             {'> '}Creativity and Imagination<br />
             {'> '}Digital Literacy<br />
             {'> '}Career-Building Skills<br />
             {'> '}Confidence and Communication Skills<br />
             {'> '}STEM Education<br />
             </p>
         </ul>
         
         <div>
           <p className='moduleSection' >
               <img src={pic1} alt=""style={{width:"2em",height:"2em"}} />
               AI & Data Science Course
           </p>
         </div>

         {/* Module one */}
         <div className="modules">
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               1
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 10
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
             Introduction to Data Science
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                Students will learn the basics of data science, including the types of data that are commonly used, such as numerical and categorical data. They will also learn about data collection & organization, using tables & graphs to make sense of large amounts of data. Finally, students will be introduced to Python to analyze & visualize data.
               </p>
             </p>
           </div>
         </div>

         {/* Module  two */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               2
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 10
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Data Cleaning and Preprocessing
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                 Students will learn about the critical role of data cleaning and preprocessing in data science and will understand different techniques for cleaning and preprocessing data, including imputation and normalization. Additionally, students will learn about basic statistics and probability concepts such as mean, median, and mode.
               </p>
              
             </p>
           </div>
         </div>

         {/* Module  Three */}
         <div className="modules" style={{ marginTop: "2vh" }}>
           {/* round box */}
           <div className="round-box">
             <p style={{ marginTop: "5vh", color: "#cff7d5" }}>
               {" "}
               Module
               <br />
               3
             </p>
           </div>
           {/* small boxes */}
           <div className="small-box" style={{ color: "#249935" }}>
             <p style={{ margin: "auto" }}>
               Activities
               <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                 10
               </p>
             </p>

             <div
               className="small-box sm-box"
             >
               <p style={{ margin: "auto" }}>
                 No.of Lessons
                 <p style={{ fontWeight: "lighter", marginBottom: "0px" }}>
                   5
                 </p>
               </p>
             </div>
           </div>

           <div className="large-box" style={{ color: "#249935" }}>
             <p className="my-4" style={{ margin: "auto" }}>
             Machine Learning
               <p
                 style={{
                   fontWeight: "lighter",
                   fontSize: "15px",
                   marginBottom: "0px",
                 }}
               >
                 This module teaches students about machine learning algorithms such as supervised & unsupervised learning, and how they make predictions & classify data. They will learn to evaluate the performance of these models & understand big data's importance. 
               </p>
              
             </p>
           </div>
         </div>

          

         <h6 style={{color:"#8b0194",fontFamily:"sans-serif"}}>
             <b>
             Learn AI & Data Science with 30+ Fun Activities
             </b>
         </h6>

      <div className="activityImagesContainer">
        <div className="row">
          <div class="col-lg mx-3">
              <img src={pic2} alt="" className='ActivityImages'/>
              <p className='mx-3'>Data Cleaning</p>
          </div>
          <div class="col-lg mx-3">
            <img src={pic3} alt="" className='ActivityImages'/>
            <p className='mx-3'>Bar Charts & Graphs</p>
          </div>
          <div class="col-lg mx-3">
            
          <img src={pic4} alt="" className='ActivityImages'/>
                        <p className='mx-3'>Data Classification</p>
          </div>
          <div class="col-lg mx-3">
            
            
          <img src={pic5} alt="" className='ActivityImages'/>
                        <p className='mx-3'>Data Normalisation</p>
          </div>
          <div class="col-lg mx-3">
            
                        <img src={pic6} alt=""  className='ActivityImages'/>
                        <p className='mx-3'>Prediction & Evaluation</p>
          </div>
          
        </div>
      </div>
      
      

       </section>

        {/* Fee Structure */}
        <FeeStructure subject="coding"/>

       {/* Testimonals */}
       <div class="  background" id="testimonal">
         <div class="row" style={{ width: "100%" }}>
           <div class="col-lg-8 mx-auto">
             <h2 className="Testimonal_Heading">Testimonials</h2>
             <div id="myCarousel" class="carousel slide" data-ride="carousel">
               {/* <!-- Carousel indicators --> */}
               <ol class="carousel-indicators">
                 <li
                   data-target="#myCarousel"
                   data-slide-to="0"
                   class="active"
                 ></li>
                 <li data-target="#myCarousel" data-slide-to="1"></li>
               </ol>
               {/* <!-- Wrapper for carousel items --> */}
               <div class="carousel-inner">
                 <div class="carousel-item active">
                   <div class="img-box">
                     <div className=" img-box1"></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I enjoyed the learning experience with EduTechSage-Abacus.
                     The teacher was very friendly. I highly recommend
                     EduTechSage-Abacus.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Mujtaba Khawaja Sahaf</b>
                   </p>
                 </div>

                 <div class="carousel-item">
                   <div class="img-box">
                     <div className="img-box2 "></div>
                   </div>
                   <p
                     class="testimonial"
                     style={{ color: "black", fontSize: "3vh" }}
                   >
                     I feel so proud when my class fellows call me ’BRAINIAC ’,
                     because I can effortlessly do mental calculations much
                     faster and accurate than my class mates. Thank you
                     Polymath-Kids.
                   </p>
                   <p style={{ color: "#ff7d00", fontSize: "3vh" }}>
                     <b>Wahaab Omer</b>, Age 9 Years
                   </p>
                 </div>
               </div>
               {/* <!-- Carousel controls --> */}
               <a
                 class="carousel-control-prev"
                 href="#myCarousel"
                 data-slide="prev"
               >
                 <i class="fa fa-angle-left"></i>
               </a>
               <a
                 class="carousel-control-next"
                 href="#myCarousel"
                 data-slide="next"
               >
                 <i class="fa fa-angle-right"></i>
               </a>
             </div>
           </div>
         </div>
       </div>

       {/* Faqs */}
       <div style={{ maxWidth: '100%', backgroundColor: '#edffff' }} id="Faq">

         <MDBContainer className="mt-0" style={{ maxWidth: '180vh' }}>
           <MDBAccordion alwaysOpen initialActive={1} >
             <div className="text-center PageHead" style={{ color: "#8b0194" }}> FAQs</div>
             <MDBAccordionItem collapseId={1} headerTitle="Q:What is data science, and why is it important?" >
               <strong>A: </strong>
               Data science is an interdisciplinary field that uses scientific methods, algorithms, and systems to extract knowledge and insights from structured and unstructured data. It is important because it helps organizations to make data-driven decisions and solve complex problems.
             </MDBAccordionItem>
             <MDBAccordionItem collapseId={2} headerTitle="Q:What are the prerequisites for learning data science?">
               <strong>A: </strong>
               A basic understanding of python, programming skills, and knowledge of database concepts are useful prerequisites for learning data science.
             </MDBAccordionItem>


             <MDBAccordionItem collapseId={3} headerTitle="Q: What topics are covered in the data science course?">
               <strong>A: </strong>
               The data science course covers topics such as data cleaning and preprocessing, statistical analysis, machine learning, data visualization, and data interpretation.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="Q: How long does it take to complete the data science course?">
               <strong>A: </strong>
               The duration of the data science course depends on the level of expertise, but generally, it takes around 15 weeks to complete.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="Q: What job opportunities are available in data science?">
               <strong>A: </strong>
               There are various job opportunities available in data science, such as data analyst, data scientist, machine learning engineer, data engineer, and business analyst.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="What kind of projects will I work on in the data science course?">
               <strong>A: </strong>
               In the data science course, you will work on projects that involve cleaning and preprocessing data, analyzing data, and building machine learning models to solve real-world problems.
             </MDBAccordionItem>

             <MDBAccordionItem collapseId={3} headerTitle="Q: How will the course be delivered, and what is the format?">
               <strong>A: </strong>
               The course will be delivered through online classes, self-paced learning, or a combination of both. The format can be in the form of video lectures, quizzes, assignments, and projects.
             </MDBAccordionItem>
           </MDBAccordion>
         </MDBContainer>

       </div>

        {/* Footer */}
        <Footer/>

   </div>
  )
}

export default AIDataScience
