import './FeeConfig.css';
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../UserContext";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import AdminNav from "../Navbars/AdminNav";
import axiosInstance from "./axiosInstance";
import { toast } from "react-toastify";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import EditFeePopup from "./EditFeePopup"; // Import the popup component


function FeeConfig() {
  const [fees, setFees] = useState({
    Course_id: '',          
    feecurrency: '',       
    GroupMonthly: 0,      
    GroupUpfront: 0,
    OneOnOneMonthly: 0,
    OneOnOneUpfront: 0,
    NoOfLessonsMin: 0,    
    NoOfLessonsMax: 0  
  }); 
  const [courses, setCourses] = useState([]);
  const [entries, setEntries] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null); // Track the selected entry for editing
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Track popup state

  const { isAdmin, setUserRole, setUserInfo, userInfo } = useContext(UserContext);

  useEffect(() => {
    fetchUserInfo();
    fetchCourses();
    fetchEntries();
  }, []);

   

  const fetchUserInfo = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}`, {
        credentials: "include",
      });
      const data = await response.json();
      setUserInfo(data);
      if (data?.usertype) {
        setUserRole(data.usertype);
      }
    } catch (error) {
      console.log("Error in fetchUserInfo:", error);
    }
  };

  const fetchCourses = () => {
    axiosInstance
      .post("/api/managecontent/getAllCourses")
      .then((response) => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          return a.coursename.localeCompare(b.coursename);
        });
        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
        toast.error("Failed to load courses", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const fetchEntries = () => {
    axiosInstance
      .get("/getFeeConfigs")
      .then((response) => {
        setEntries(response.data);
      })
      .catch((error) => {
        console.error("Error fetching fee configurations:", error);
        toast.error("Failed to load fee configurations", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleEditConfig = (entry) => {
    setSelectedEntry(entry); // Set the selected entry for editing
    setIsPopupOpen(true); // Open the popup window
  };

  const handleDelete = (Course_id) => {
    axiosInstance
      .post('/deleteFeeConfig', { Course_id })
      .then(response => {
        if (response.data.success) {
          toast.success("Fee configuration deleted successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setEntries(entries.filter(entry => entry.Course_id !== Course_id));
        } else {
          toast.error("Failed to delete fee configuration", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(error => {
        console.error("Error deleting fee configuration:", error);
        toast.error("An error occurred while deleting fee configuration", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //Update Fee
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFees((prevFees) => ({
      ...prevFees,
      [name]: value,
    }));
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Close the popup window
  };

  const handleUpdateEntry = (formattedEntry) => {
    console.log("Sending update request with data:", formattedEntry); // Log the data being sent
  
    axiosInstance.post('/updateFeeConfig', formattedEntry)
      .then(response => {
        console.log("Response from update request:", response.data); // Log the response data
        console.log(response.data);
        console.log(response.data.success);
        if (response.data.success) {
          fetchEntries(); // Refresh entries after update
          toast.success("Fee configuration updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsPopupOpen(false); // Close the popup window
        } else {
          toast.error("Failed to update fee configuration", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(error => {
        console.error("Error updating fee configuration:", error.response ? error.response.data : error.message);
        toast.error("An error occurred while updating fee configuration", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  

   //Save Fee Configuration
   const handleSubmit = (event) => {
    event.preventDefault();

    const courseName = courses.find(course => course.Course_id === fees.Course_id)?.coursename || '';

    axiosInstance.post('/saveFeeConfig', {
        Course_id: fees.Course_id, // Use course_id here
        feecurrency: fees.feecurrency,
        GroupMonthly: fees.GroupMonthly,
        GroupUpfront: fees.GroupUpfront,
        OneOnOneMonthly: fees.OneOnOneMonthly,
        OneOnOneUpfront: fees.OneOnOneUpfront,
        NoOfLessonsMin: fees.NoOfLessonsMin,
        NoOfLessonsMax: fees.NoOfLessonsMax
      })
      .then(response => {
        if (response.data.success) {
            fetchEntries();
            const newEntry = {
                ...fees,
                coursename: courseName,
              };
              setEntries([...entries, newEntry]);
          //setEntries([...entries, response.data.data]);
          toast.success("Fee configuration saved successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Failed to save fee configuration", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(error => {
        console.error("Error saving fee configuration:", error);
        toast.error("An error occurred while saving fee configuration", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    // Clear form after submission
    setFees({
      Course_id: '',
      feecurrency: '',
      GroupMonthly: 0,
      GroupUpfront: 0,
      OneOnOneMonthly: 0,
      OneOnOneUpfront: 0,
      NoOfLessonsMin: 0,
      NoOfLessonsMax: 0
    });
  };
  const navigate = useNavigate();

  const onLogout = () => {
    fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: "include",
      method: "POST",
    })
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Logout failed:", error);
      });
    setUserInfo(null);
    setUserRole("guest");
  };

  const username = userInfo?.username;

  return (
    <>
      {username && isAdmin && <AdminNav username={username} onLogout={onLogout} />}
      <div className="fee-config-container">
        <h2 className='fee-config-heading'>Fee Configuration</h2>
        <form onSubmit={handleSubmit}>
        <div className="form-flex-container">
          <div className="fee-input-group">
            <label htmlFor="Course_id">Choose a Course</label>
            <select id="Course_id" name="Course_id" value={fees.Course_id} onChange={handleChange} className="fee-select">
              <option value="">Choose a Course</option>
              {courses.map((course) => (
                <option key={course.Course_id} value={course.Course_id}>
                  {course.coursename}
                </option>
              ))}
            </select>
          </div>
          <div className="fee-input-group">
            <label htmlFor="feecurrency">Choose Currency</label>
            <select id="feecurrency" name="feecurrency" value={fees.feecurrency} onChange={handleChange} className="fee-select">
              <option value="">Choose Currency</option>
              <option value="PKR">PKR</option>
              <option value="USD">USD</option>
              <option value="AUD">AUD </option>
              <option value="Pounds"> Pounds </option>
              <option value="Euros"> Euros </option>
              <option value="UAE Dirhams"> UAE Dirhams </option>
              <option value="Riyal"> Riyal </option>
              <option value="Canadian Dollars"> Canadian Dollars </option>
            </select>
          </div>
          <div className="fee-input-group">
            <label htmlFor="GroupMonthly">Group Monthly</label>
            <input
              type="number"
              id="GroupMonthly"
              name="GroupMonthly"
              value={fees.GroupMonthly}
              onChange={handleChange}
              className="fee-input"
              placeholder="Group Monthly"
            />
          </div>
          <div className="fee-input-group">
            <label htmlFor="GroupUpfront">Group Upfront</label>
            <input
              type="number"
              id="GroupUpfront"
              name="GroupUpfront"
              value={fees.GroupUpfront}
              onChange={handleChange}
              className="fee-input"
              placeholder="Group Upfront"
            />
          </div>
          <div className="fee-input-group">
            <label htmlFor="OneOnOneMonthly">One on One Monthly</label>
            <input
              type="number"
              id="OneOnOneMonthly"
              name="OneOnOneMonthly"
              value={fees.OneOnOneMonthly}
              onChange={handleChange}
              className="fee-input"
              placeholder="One on One Monthly"
            />
          </div>
          <div className="fee-input-group">
            <label htmlFor="OneOnOneUpfront">One on One Upfront</label>
            <input
              type="number"
              id="OneOnOneUpfront"
              name="OneOnOneUpfront"
              value={fees.OneOnOneUpfront}
              onChange={handleChange}
              className="fee-input"
              placeholder="One on One Upfront"
            />
          </div>
          <div className="fee-input-group">
            <label htmlFor="NoOfLessonsMin">No of Lessons Min (Monthly)</label>
            <input
              type="number"
              id="NoOfLessonsMin"
              name="NoOfLessonsMin"
              value={fees.NoOfLessonsMin}
              onChange={handleChange}
              className="fee-input"
              placeholder="No of Lessons Min (Monthly)"
            />
          </div>
          <div className="fee-input-group">
            <label htmlFor="NoOfLessonsMax">No of Lessons Max (Upfront)</label>
            <input
              type="number"
              id="NoOfLessonsMax"
              name="NoOfLessonsMax"
              value={fees.NoOfLessonsMax}
              onChange={handleChange}
              className="fee-input"
              placeholder="No of Lessons Max (Upfront)"
            />
            </div>
          </div>
          <button type="submit" className="submit-button">Save</button>
        </form>
      </div>
      <div className="fee-table-container">
        <table className="fee-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>No of Lessons Min (Monthly)</th>
              <th>No of Lessons Max (Upfront)</th>
              <th>Currency</th>
              <th>Group Monthly Fee</th>
              <th>Group Upfront Fee</th>
              <th>1 on 1 Monthly Fee</th>
              <th>1 on 1 Upfront Fee</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry, index) => (
              <tr key={index}>
                <td>{courses.find(course => course.Course_id === entry.Course_id)?.coursename}</td>
                <td>{entry.NoOfLessonsMin}</td>
                <td>{entry.NoOfLessonsMax}</td>
                <td>{entry.feecurrency}</td>
                <td>{entry.GroupMonthly}</td>
                <td>{entry.GroupUpfront}</td>
                <td>{entry.OneOnOneMonthly}</td>
                <td>{entry.OneOnOneUpfront}</td>
                <td>
                  <button className="edit-button" onClick={() => handleEditConfig(entry)}><BsFillPencilFill /></button>
                  <BsFillTrashFill className="delete-btn" onClick={() => handleDelete(entry.Course_id)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
      {isPopupOpen && (
        <EditFeePopup
          entry={selectedEntry}
          courses={courses}
          onClose={handleClosePopup}
          onSave={handleUpdateEntry}
        />
      )}
    </>
  );
}

export default FeeConfig;