import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import axiosInstance from "./axiosInstance";
import "./managecontentstyle/managecontent.css";
import { toast } from "react-toastify";
import "./managecontentstyle/buttonstyle.css";

function CreateModuleLesson() {
  const [course, setCourse] = useState("");
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [module, setModule] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [lessonNo, setLessonNo] = useState("");
  const [keyTerms, setKeyTerms] = useState("");
  const [materials, setMaterials] = useState("");
  const [objectives, setObjectives] = useState("");

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance
      .post("/api/managecontent/getAllCourses")
      .then((response) => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchModules = (courseId) => {
    axiosInstance
      .post("/api/managecontent/getAllModule", { courseId })
      .then((response) => {
        const filteredModules = response.data.filter(
          (module) => module.Course_id === parseInt(courseId, 10)
        );
        filteredModules.sort((a, b) => a.ModuleNumber - b.ModuleNumber);
        setModules(filteredModules);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleCourseSelect = (courseId) => {
    setCourse(courseId);
    fetchModules(courseId);
  };

  const handleModuleSelect = (moduleId) => {
    setModule(moduleId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit is called.");

    const payload = {
      Module_id: module,
      lessondescription: description,
      lessonname: name,
      lessonno: lessonNo,
      learningobjective: objectives,
      materialandpreparation: materials,
      keyterms: keyTerms,
    };

    try {
      await axiosInstance.post("/api/managecontent/addLesson", payload);
      console.log("Lesson added successfully.");
      toast.success("Module Lesson has been Added", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setCourse("");
      setModule("");
      setDescription("");
      setName("");
      setLessonNo("");
      setObjectives("");
      setMaterials("");
      setKeyTerms("");
    } catch (error) {
      console.error("Internal server error", error);
      toast.error("Error: Unable to add lesson", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="create-module-lesson">
        <h2>Create Module Lesson</h2>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="course-select">Course*</Label>
            <Input
              type="select"
              name="select"
              id="course-select"
              value={course}
              required
              onChange={(event) => handleCourseSelect(event.target.value)}
            >
              <option>Select a course</option>
              {courses.map((course) => (
                <option key={course.id} value={course.Course_id}>
                  {course.coursename}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="module-select">Module*</Label>
            <Input
              type="select"
              name="select"
              id="module-select"
              value={module}
              required
              onChange={(event) => handleModuleSelect(event.target.value)}
            >
              <option>Select a Module</option>
              {modules.map((module) => (
                <option key={module.id} value={module.Module_id}>
                  {module.modulename}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="name-input">Name*</Label>
            <Input
              type="text"
              name="name"
              required
              id="name-input"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description-input">Description</Label>
            <Input
              type="textarea"
              name="description"
              
              id="description-input"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lesson-no-input">Lesson No.*</Label>
            <Input
              type="text"
              name="lesson-no"
              required
              id="lesson-no-input"
              placeholder="Enter lesson number"
              value={lessonNo}
              onChange={(e) => setLessonNo(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="key-terms-input">Key Terms</Label>
            <Input
              type="textarea"
              name="key-terms"
              id="key-terms-input"
              placeholder="Enter key terms"
              value={keyTerms}
              onChange={(e) => setKeyTerms(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="materials-input">Materials and Prep</Label>
            <Input
              type="textarea"
              name="materials"
              
              id="materials-input"
              placeholder="Enter materials and prep"
              value={materials}
              onChange={(e) => setMaterials(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="objectives-input">Objectives</Label>
            <Input
              type="textarea"
              name="objectives"
              
              id="objectives-input"
              placeholder="Enter objectives"
              value={objectives}
              onChange={(e) => setObjectives(e.target.value)}
            />
          </FormGroup>
          <div className="inz-button">
            <button variant="primary" type="submit" className="inz-save-button">
              Save
            </button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default CreateModuleLesson;
