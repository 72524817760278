import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

const InvoiceRow = ({ invoice, isOverdue }) => {


    console.log("Invoice is: ", invoice);

    const tableStyles = {
        width: '80vw',
        borderCollapse: 'collapse',
        fontSize: '14px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f5f5f5'
      };
    
      const tableContainerStyles = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px'
      };
    
      const tableHeaderStyles = {
        backgroundColor: '#fcbf14',
        padding: '10px',
        textAlign: 'left',
        fontWeight: 'bold'
      };
    
      const tableBodyStyles = {
        padding: '10px',
        borderBottom: '1px solid #ddd'
      };
    
      const paidColumnStyles = {
        width: '80px',
        textAlign: 'center'
      };
    
      const uploadColumnStyles = {
        width: '120px',
        textAlign: 'center'
      };



    const [invoicePDF, setInvoicePDF] = useState(null);

    const handleInvoicePDF = async () => {
        if (invoice.invoiceFile != undefined) {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_LOCAL_SERVER_API}/getInvoiceFile/${invoice.invoiceFile}`,
                {
                  responseType: "blob",
                }
              );
    
              // Convert the Blob to a URL that can be used in an <iframe>
              const url = URL.createObjectURL(response.data);
              setInvoicePDF(url);
            } catch (error) {
              console.error("Error fetching PDF:", error);
            }
          } else {
            setInvoicePDF(null);
          }
      };


      useEffect(() => {
        const fetchInvoicePDF = async () => {
          try {
            await handleInvoicePDF();
          } catch (error) {
            console.error("Error fetching invoice PDF:", error);
          }
        };
      
        fetchInvoicePDF();
      }, [invoice.invoiceFile]);


      function handlePaymentRedirect(invoice) {
        // Construct the URL for the /makepayment route with the invoice parameters
        const url = `/MakePaymentPage?fee_id=${invoice.feeId}&invoiceNumber=${invoice.invoiceNumber}`;
      
        // Open the payment redirect URL in a new tab
        window.open(url, '_blank');
      }


  return (
    <tr>
    <td style={{ ...tableBodyStyles, ...paidColumnStyles }}>
        <input type="checkbox" checked={invoice.paid} readOnly />
    </td>
    <td>
    {invoicePDF ? (
        <a href={invoicePDF} target="_blank" rel="noopener noreferrer">
        <p> Invoice No. {invoice.invoiceNumber}  </p>
        </a>
    ) : (
        <p onClick={() => handlePaymentRedirect(invoice)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}> Invoice No. {invoice.invoiceNumber} </p>
    )}
    </td>
    <td style={tableBodyStyles}>${invoice.amount}</td>
    <td
        style={{
        ...tableBodyStyles,
        color: isOverdue && !invoice.paid ? 'red' : 'inherit',
        }}
    >
        {invoice.dueDate}
    </td>
    <td style={{ ...tableBodyStyles, ...uploadColumnStyles }}>
        {!invoice.paid && (
        <button
            disabled
            // onClick={() => handlePaymentRedirect(invoice)}
            style={{
              backgroundColor: '#d3d3d3', // Light gray for a disabled look
              color: '#a9a9a9', // Darker gray for text
              border: 'none',
              borderRadius: '8px',
              padding: '10px 20px',
              fontSize: '14px',
              fontWeight: 'bold',
              cursor: 'not-allowed', // Makes it clear that the button is disabled
              minWidth: '100px',
              transition: 'all 0.3s ease',
            '&:hover': {
                backgroundColor: '#005a8e',
                transform: 'scale(1.05)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            },
            '&:active': {
                transform: 'scale(0.95)',
                boxShadow: 'none',
            },
            }}
        >
            Pay
        </button>
        )}
    </td>
    </tr>
  );
};

export default InvoiceRow;