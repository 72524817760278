import React, { useState, useEffect } from 'react'
import './User.css'
import ProfilePic from "./user.png";
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link } from 'react-router-dom'

const UserCard = ({ user, updateUserType, updateUserStatus }) => {

  const [courses, setCourses] = useState();
  const [modules, setModules] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');
  const [currentSelectedCourse, setCurrentSelectedCourse] = useState('');
  const [currentSelectedModule, setCurrentSelectedModule] = useState('');
  const [currentSelectedLesson, setCurrentSelectedLesson] = useState('');

  // State for search filters
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');

  useEffect(() => {
    // Fetch courses
    // console.log("studentId:user?.Student_id,studentName:user?.firstname",user,Student_id)
    if (user.usertype === 'Student') {
      axios
        .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCoursenamesByUserId/${user.User_id}`)
        .then((response) => {
          // console.log('Courses:', response.data);
          // console.log('All the Courses against the UserId:', response.data.courses);
          setCourses(response.data.courses);
        })
        .catch((error) => {
          console.error('Error fetching courses:', error);
        });
    }

    //Get the Current Status of the Student
    if (user.usertype === 'Student') {
      axios
        .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCurrentStatus/${user.User_id}`)
        .then((response) => {
          const { course_id, module_id, lesson_id } = response.data;

          if (course_id != undefined) {
            // console.log("Object is not Null...")

            // console.log("Current Status: ", course_id, module_id, lesson_id);

            // Fetch course name based on course_id
            axios
              .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourseName/${course_id}`)
              .then((response) => {
                // console.log("It's Course Name:", response.data.course);
                setSelectedCourse(response.data.course);
                setCurrentSelectedCourse(response.data.course);
              })
              .catch((error) => {
                console.error('Error fetching course name:', error);
              });


            //Get all the modules against that course
            axios
              .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getModulesByCourse/${course_id}`)
              .then((response) => {
                // console.log("Module Response: ", response.data)
                if (response.data.Data !== false) {
                  // console.log("I am here assigning modules... ")
                  setModules(response.data);
                }
              })
              .catch((error) => {
                console.error('Error fetching modules:', error);
              });

            // Fetch module name based on module_id
            axios
              .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getModuleName/${module_id}`)
              .then((response) => {
                // console.log('Module Name is yes:', response.data.module);
                setSelectedModule(response.data.module);
                setCurrentSelectedModule(response.data.module);
              })
              .catch((error) => {
                console.error('Error fetching module name:', error);
              });


            //Get Lessons
            axios
              .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getLessonsByModule/${module_id}`)
              .then((response) => {
                // console.log('Lessons:', response.data);
                if (response.data.Data !== false) {
                  // console.log("I am here assigning modules... ")
                  setLessons(response.data);
                }

              })
              .catch((error) => {
                console.error('Error fetching lessons:', error);
              });

            // Fetch lesson name based on lesson_id
            axios
              .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getLessonName/${lesson_id}`)
              .then((response) => {
                // console.log('Lesson Name:', response.data.lesson);
                setSelectedLesson(response.data.lesson);
                setCurrentSelectedLesson(response.data.lesson);
              })
              .catch((error) => {
                console.error('Error fetching lesson name:', error);
              });

          }

        })
        .catch((error) => {
          console.error('Error fetching courses:', error);
        });




    }





  }, []);

  const handleCourseChange = (event) => {
    const courseId = event.target.value;


    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getCourseName/${courseId}`)
      .then((response) => {
        // console.log("It's Course:", response.data.course);
        setSelectedCourse(response.data.course);
        setCurrentSelectedCourse(response.data.course);
      })
      .catch((error) => {
        console.error('Error fetching course:', error);
      });

    // console.log("This is Selected Course Object: ", event.target.value);

    // setSelectedCourse(courseId);
    // setSelectedModule("");
    // setSelectedLesson("");
    if (courseId == currentSelectedCourse.Course_id) {

      // console.log("I am inside...")
      // console.log("currentSelectedModule: ", currentSelectedModule)
      // console.log("currentSelectedLesson: ", currentSelectedLesson)
      setSelectedModule(currentSelectedModule);
      setSelectedLesson(currentSelectedLesson);
    }
    else {
      setSelectedModule("");
      setSelectedLesson("");
    }

    // Fetch modules based on the selected course
    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getModulesByCourse/${courseId}`)
      .then((response) => {
        // console.log('Modules:', response.data);
        if (response.data.Data !== false) {
          // console.log("I am here assigning modules... ")
          setModules(response.data);
        }

      })
      .catch((error) => {
        console.error('Error fetching modules:', error);
      });
  };

  const handleModuleChange = (event) => {

    // console.log("I am in Module Change.....")
    const moduleId = event.target.value;


    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getModuleName/${moduleId}`)
      .then((response) => {
        // console.log('Module Name is yes:', response.data.module);
        setSelectedModule(response.data.module);
        setCurrentSelectedModule(response.data.module);
      })
      .catch((error) => {
        console.error('Error fetching module name:', error);
      });



    // setSelectedModule(moduleId);

    setSelectedLesson("");
    if (moduleId == currentSelectedModule.Module_id) {
      setSelectedLesson(currentSelectedLesson);
    }
    else {
      setSelectedLesson("");
    }

    // Fetch lessons based on the selected module
    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getLessonsByModule/${moduleId}`)
      .then((response) => {
        // console.log('Lessons:', response.data);
        if (response.data.Data !== false) {
          console.log("I am here assigning modules... ")
          setLessons(response.data);
        }

      })
      .catch((error) => {
        console.error('Error fetching lessons:', error);
      });
  };

  const handleLessonChange = (event) => {
    const Lesson_id = event.target.value;
    // console.log("I am in checking Lesson ID: ")
    // console.log("Event.target lesson is: ", Lesson_id)
    // console.log("SetCurrent Lesson ID is: ", currentSelectedLesson)


    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getLessonName/${Lesson_id}`)
      .then((response) => {
        console.log('Lesson Name:', response.data.lesson);
        setSelectedLesson(response.data.lesson);
        setCurrentSelectedLesson(response.data.lesson);
      })
      .catch((error) => {
        console.error('Error fetching lesson name:', error);
      });


    // setSelectedLesson(Lesson_id);

  }


  const saveStudentModule = (userId, moduleId) => {

    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveModule`;

    // Get Student_id based on user.user_Id
    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {

        const studentId = response.data.Student_id;
        console.log("Student ID = :", studentId)
        const startDate = new Date().toISOString().split('T')[0]; // Get the current date as the start date
        const data = {
          Module_id: moduleId,
          Student_id: studentId,
          StartDate: startDate,
          EndDate: null,
          Score: 0,
          Complete: 0,
        };

        // Save the module for the user
        axios
          .post(apiUrl, data)
          .then((response) => {
            console.log('Module saved successfully:', response.data);
            // Handle any further actions after successful module save
          })
          .catch((error) => {
            console.error('Error saving module:', error);
            // Handle error cases
          });
      })
      .catch((error) => {
        console.error('Error fetching student ID:', error);
        // Handle error cases
      });
  };



  //Saving the Student Lesson
  const saveStudentLesson = (userId, lessonId) => {
    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveLesson`;

    // Get Student_id based on user.user_Id
    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {
        const studentId = response.data.Student_id;
        console.log("Student ID:", studentId);
        const startDate = new Date().toISOString().split('T')[0]; // Get the current date as the start date
        const data = {
          Lesson_id: lessonId,
          Student_id: studentId,
          StartDate: startDate,
          EndDate: null,
          Complete: 0,
        };

        // Save the lesson for the user
        axios
          .post(apiUrl, data)
          .then((response) => {
            console.log('Lesson saved successfully:', response.data);
            // Handle any further actions after successful lesson save
          })
          .catch((error) => {
            console.error('Error saving lesson:', error);
            // Handle error cases
          });
      })
      .catch((error) => {
        console.error('Error fetching student ID:', error);
        // Handle error cases
      });
  };




  // Saving the Student Module Project Assignment
  const saveStudentModuleProjectAssignment = (userId, moduleId) => {
    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudentModuleProjectAssignments`;

    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {
        const studentId = response.data.Student_id;
        console.log("Student ID:", studentId);


        const currentDate = new Date(); // Get the current date
        const submissionDate = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000)); // Add 7 days to the current date

        const data = {
          Module_id: moduleId,
          Student_id: studentId,
          SubmissionDate: submissionDate.toISOString().split('T')[0],
        };

        // Save the module project assignment for the student
        axios
          .post(apiUrl, data)
          .then((response) => {
            console.log('Module project assignment saved successfully:', response.data);
            // Handle any further actions after successful save
          })
          .catch((error) => {
            console.error('Error saving module project assignment:', error);
            // Handle error cases
          });

      })
  };



  const saveStudentLessonProjectAssignment = (userId, lessonId) => {
    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudentLessonProjectAssignments`;

    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {
        const studentId = response.data.Student_id;
        console.log("Student ID:", studentId);

        const currentDate = new Date(); // Get the current date
        const submissionDate = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000)); // Add 7 days to the current date

        const data = {
          Lesson_id: lessonId,
          Student_id: studentId,
          SubmissionDate: submissionDate.toISOString().split('T')[0],
        };

        // Save the lesson project assignment for the student
        axios
          .post(apiUrl, data)
          .then((response) => {
            console.log('Lesson project assignment saved successfully:', response.data);
            // Handle any further actions after successful save
          })
          .catch((error) => {
            console.error('Error saving lesson project assignment:', error);
            // Handle error cases
          });
      });
  };



  const saveStudentLessonActivities = (userId, lessonId) => {
    const apiUrl = `${process.env.REACT_APP_LOCAL_SERVER_API}/saveStudentLessonActivities`;

    axios
      .get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getStudentId/${userId}`)
      .then((response) => {
        const studentId = response.data.Student_id;
        console.log("Student ID:", studentId);

        const data = {
          Lesson_id: lessonId,
          Student_id: studentId,
        };

        // Save the lesson project assignment for the student
        axios
          .post(apiUrl, data)
          .then((response) => {
            console.log('Student Lesson Activities saved successfully:', response.data);
            // Handle any further actions after successful save
          })
          .catch((error) => {
            console.error('Error saving Student Lesson Activities:', error);
            // Handle error cases
          });
      });
  };






  const handleSave = () => {
    // Check if all fields are selected
    if (!selectedCourse || !selectedModule || !selectedLesson) {
      toast.error('Please select a course, module, and lesson.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;
    }

    // Make API call to save the data
    const data = {
      user_id: user.User_id,
      course_id: selectedCourse.Course_id,
      module_id: selectedModule.Module_id,
      lesson_id: selectedLesson?.Lesson_id,
    };

    axios
      .post(`${process.env.REACT_APP_LOCAL_SERVER_API}/saveUserCurrentStatus`, data)
      .then((response) => {
        console.log('Save response:', response.data);
        toast.success('Data saved successfully.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error('Error saving data:', error);
        toast.error('Error saving data.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      });

    //Insert Student_Module
    saveStudentModule(user.User_id, selectedModule.Module_id);
    saveStudentLesson(user.User_id, selectedLesson?.Lesson_id);
    saveStudentModuleProjectAssignment(user.User_id, selectedModule.Module_id);
    saveStudentLessonProjectAssignment(user.User_id, selectedLesson?.Lesson_id);
    saveStudentLessonActivities(user.User_id, selectedLesson?.Lesson_id);


  };





  const [userType, setUserType] = useState(user.usertype);
  const [deactivated, setDeactivated] = useState(user.deactivated);

  const handleUserTypeChange = (event) => {
    const selectedUserType = event.target.value;
    console.log(user.User_id, selectedUserType);
    updateUserType(user.User_id, selectedUserType, userType)
      .then(() => {
        // Update the user type in the local state
        setUserType(selectedUserType);
        toast.success('User Type has changed.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        console.error('Error updating user usertype:', error);
      });
  };
  const handleDeactivatedChange = (event) => {
    const isChecked = event.target.checked;
    setDeactivated(isChecked);
    const userStatus = isChecked ? 1 : 0;
    console.log(user.User_id, userStatus);
    updateUserStatus(user.User_id, userStatus)
      .then(() => {
        if (userStatus == 1)
          toast.info(`${user.username} has been Deactivated.`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        else
          toast.info(`${user.username} has been Activated.`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
      })
      .catch((error) => {
        console.error('Error updating user activation status:', error);
      });
  };

  return (
    <>
      <div className="MAcard" style={{ padding: "1px 10px" }}>
        <div className="MAcard-head">
          <div className="MAdeactive-status">
            <input
              className="MAdeactive-checkbox"
              type="checkbox"
              checked={deactivated}
              onChange={handleDeactivatedChange}
            />
            <p id="deactivate-text">Deactivated</p>
          </div>


          <div className="MAprofile-picture">
            <img className="MAuser-profile-picture" src={ProfilePic} alt="profile-picture" />
          </div>
          <div className="MAuser-role">
            <select
              className="MAdropdown-menu"
              name="role"
              value={userType}
              onChange={handleUserTypeChange}
            >
              <option value="Admin">Admin</option>
              <option value="Student">Student</option>
              <option value="Teacher">Teacher</option>
            </select>
          </div>
        </div>


        {/* <!--User details--> */}

        <div className="MAuser-details">
          <div className="MAname-email-address">
            <p><b>Username: </b>{user.username}</p>
            <p><b>Parent: </b>{user.parentname}</p>
            <p>{user.email}</p>
            <p>{user.address}</p>
            <p><i>{userType}</i></p>
          </div>
          <div className="MAdob-phone">
            <p>{user.dateofbirth}</p>
            <p>{user.phonenumber}</p>

          </div>
        </div>

        {/* <!--Current Course and Module--> */}

        <div className="MAcourse-module" style={{ padding: "1px" }}>

          <div className="MAcourse" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            {courses ? (
              <select className="MAdropdown-menu MAdropdown-fixed-width" name="course" onChange={handleCourseChange}>
                <option value="">{selectedCourse ? selectedCourse.coursename : 'Select a Course'}</option>
                {courses
                  .filter((course) => selectedCourse.Course_id !== course.Course_id)
                  .map((course) => (
                    <option key={course.Course_id} value={course.Course_id}>
                      {course.coursename}
                    </option>
                  ))}
              </select>

            ) : (
              <select className="MAdropdown-menu MAdropdown-fixed-width" name="course">
                <option value="">No courses available</option>
              </select>
            )}
          </div>

          <div className="MAmodule" style={{width:"100%",display:"flex",justifyContent:"center"}}>
            {modules ? (
              <select className="MAdropdown-menu MAdropdown-fixed-width" name="module" onChange={handleModuleChange}>
                <option value="">{selectedModule ? selectedModule.modulename : 'Select a Module'}</option>
                {modules
                  .filter((module) => selectedModule.Module_id !== module.Module_id)
                  .map((module) => (
                    <option key={module.Module_id} value={module.Module_id}>
                      {module.modulename}
                    </option>
                  ))}
              </select>
            ) : (
              <select className="MAdropdown-menu MAdropdown-fixed-width" name="module">
                <option value="">Select a Module</option>
              </select>
            )}
          </div>


          <div className="MAlesson" style={{width:"100%",display:"flex",justifyContent:"center"}}>
            {lessons ? (
              <select className="MAdropdown-menu MAdropdown-fixed-width" name="lesson" onChange={handleLessonChange}>
                <option value="">{selectedLesson ? selectedLesson.lessonName : 'Select a Lesson'}</option>
                {lessons
                  .filter((lesson) => selectedLesson?.Lesson_id !== lesson?.Lesson_id)
                  .map((lesson) => (
                    <option key={lesson?.Lesson_id} value={lesson?.Lesson_id}>
                      {lesson.lessonName}
                    </option>
                  ))}
              </select>
            ) : (
              <select className="MAdropdown-menu MAdropdown-fixed-width" name="lesson">
                <option value="">Select a Lesson</option>
              </select>
            )}
          </div>


          <div className="MAsave-button">
            <button onClick={handleSave}>
              Save
            </button>
          </div>

        </div>

        {/* <!--message--> */}

        <div className="MAtype-message">
          <input type="text" placeholder="Type Message here..." />
        </div>

        {/* <!--Card Footer--> */}

        <div className="MAcard-foot">
          <div className="MAupper-left">
            <Link to="/StudentProgress" state= {{studentId:user?.Student_id,studentName:user?.firstname}}>Progress</Link>

          </div>

          <div className="MAupper-right">
            <Link to="/StudentFee">Fee</Link>
          </div>
          {userType === 'Student' &&
            <div className="MAlower-left">
              <Link to={`/AdminRegisterInCourse?username=${user.username}&User_id=${user.User_id}`}>Register in a Course</Link>
            </div>
          }

          {(userType === 'Teacher'||userType === 'Student') &&
            <div className="MAlower-right">
              {/* <a href="">Change Teacher & Class</a> */}
              <Link to={`/ChangeTeacherAndClass?username=${user.username}&User_id=${user.User_id}`}>Change Teacher & Class</Link>
            </div>
          }
        </div>

      </div>
    </>
  )
}

export default UserCard;
