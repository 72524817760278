import React, { useState, useRef, useEffect } from 'react';
import Logo from '../CourseImages/Logo.png';
import './Nav.css';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { fetchImageData } from './fetchImage';
import dummy_user from "./dummy_user.png";

const StudentNav = ({ username, avatar, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSelectedCourse(null);
  };

  const handleCourseSelection = (course) => {
    setIsOpen2(!isOpen2);
    setSelectedCourse(course);
  };
  const [courses, setCourses] = useState([]);

  const fetchStudentRegisteredCourses = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/fetchStudentRegisteredCourses`,
        {
          credentials: 'include',
        });
      if (response.ok) {
        const data = await response.json();
        console.log("coursesnames:", data.courseNames)
        setCourses(data.courseNames);
        fetchTeacherIds(data.courseNames);

        // fetchTeacherIds(data.courseNames[0].Class_id)
      } else {
        console.error('Failed to fetch courses:', response.status);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };
  const [teacherIds, setTeacherIds] = useState([]);
  const fetchTeacherIds = async (courses) => {
    // console.log("StudentNav:",courses)
    try {
      console.log("courses fetchTeacherIds:", courses)

      const updatedCourses = await Promise.all(courses.map(async (course) => {
        if (course.Class_id !== undefined && course.Class_id) {
          console.log("courses.Class_id:", course.Class_id)
          const response = await axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getTeacherIdsViaClassId/${course.Class_id}`);
          // console.log("response:",response)
          if (response.status === 200) {
            const responseData = response.data;
            // console.log("responseData.Teacher_ids",responseData.Teacher_ids)
            return { ...course, teacherIds: responseData.Teacher_ids };
          }
          else {
            console.error('Failed to fetch teacher IDs for class', course.Class_id, ':', response.status);
            return { ...course, teacherIds: [] }; // Empty array if request fails
          }
        }
        else {
          return { ...course }; // Empty array if request fails
        }
      }));
      setCourses(updatedCourses)
      return updatedCourses;
    } catch (error) {
      console.error('Error fetching teacher IDs:', error);
      return courses; // Return original courses data if an error occurs
    }
  };
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchImageData(setImageData, avatar);
    fetchStudentRegisteredCourses();
    // console.log(courses)
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <nav class="navbar navbar-expand-lg navbar-light " style={{ backgroundColor: "#edffff" }}>
          <div class="container-fluid">
            <img src={Logo} alt="Logo" className="pageLogo" />
            <button style={{ marginLeft: "auto" }} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse mx-3  Navbar-Right-Aligned" id="navbarSupportedContent">
              <ul class="navbar-nav" style={{ marginLeft: "10%" }}>
                <li class="nav-item active  ">
                  <Link class="nav-link navLinks" to="/">
                    Home
                    <span class="sr-only">(current)</span>
                  </Link>
                </li>
                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/RegisterInCourse">
                    Register in a course
                  </Link>
                </li>
                <li className="dropdown-container" ref={dropdownRef}>
                  <a className="nav-link dropdown-toggle navLinks" onClick={toggleDropdown}>
                    My Courses
                  </a>
                  {isOpen && (
                    <li className="dropdown-menu-scrollable dropdown-menu_">
                      {courses && courses.length > 0 ? (
                        courses.map((course) => (
                          <li key={course}>
                            <a
                              className={`nav-link navLinks dropdown-item_ ${selectedCourse === course ? 'active' : ''}`}
                              onClick={() => handleCourseSelection(course)}
                              style={{ padding: "0" }}
                            >
                              <span style={{ width: "30vh", padding: "1vh" }} className="dropdown-toggle course-text">
                                {course.coursename}
                              </span>
                            </a>
                            {isOpen2 && selectedCourse === course && (
                              <li className="nested-dropdown-menu_">
                                {/* {console.log("course object:",course)}
                              {console.log("course.teacherIds object:",course.teacherIds)} */}
                                <Link
                                  to={{
                                    pathname: "/AttendClass",
                                    search: `?classLink=${encodeURIComponent(JSON.stringify(course.classlink))}&coursename=${encodeURIComponent(course.coursename)}&Course_id=${course.Course_id}${course.teacherIds && course.teacherIds.length > 0 ? `&teacherIds=${encodeURIComponent(course.teacherIds.join(','))}` : ''}`,
                                  }}
                                  style={{ color: "#03b4c6", paddingLeft: "4vh", pointerEvents: course.teacherIds && course.teacherIds.length > 0 ? 'auto' : 'none', opacity: course.teacherIds && course.teacherIds.length > 0 ? 1 : 0.5 }}
                                  className="dropdown-item_ "
                                >
                                  Attend Class
                                </Link>
                                {/* <Link
                                    to={{
                                        pathname: "/AttendClass",
                                        search: `?classLink=${encodeURIComponent(JSON.stringify(course.classlink))}&coursename=${encodeURIComponent(course.coursename)}&Course_id=${course.Course_id}${course.teacherIds && course.teacherIds.length > 0 ? `&teacherIds=${encodeURIComponent(course.teacherIds.join(','))}` : ''}`,
                                    }}
                                    style={{ color: "#03b4c6", paddingLeft: "4vh" }}
                                    className="dropdown-item_ "

                                >
                                    Attend Class
                                </Link> */}

                                <br />
                                <Link to="/MyProgress" style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">
                                  My Progress
                                </Link>
                                <br />
                                <Link to={`/ActivitiesAndProjects/${course.Course_id}`}
                                  // state={{ courseId: course.Course_id }}
                                  style={{ color: "#03b4c6", paddingLeft: "4vh" }} className="dropdown-item_ ">
                                  Activities & Projects
                                </Link>
                                <br />
                              </li>
                            )}
                          </li>
                        ))
                      ) : (
                        <li>
                          <span style={{ width: "30vh", padding: "1vh" }} className="course-text">
                            No Registered Course.
                          </span>
                        </li>
                      )}
                    </li>
                  )}
                </li>

                <li class="nav-item ">
                  <a class="nav-link navLinks" href="/myrewards">
                    My Rewards
                  </a>
                </li>
                <li class="nav-item ">
                  <a class="nav-link navLinks" href="/studentInvoices">
                    My Fees
                  </a>
                </li>

                <li class="nav-item ">
                  <Link class="nav-link navLinks" to="/calenderview">
                    My Calender View
                  </Link>
                </li>

                <i class="fa-regular fa-bell mx-3 my-auto" style={{ cursor: "pointer", color: "blue" }}></i>
                <i class="fa-solid fa-sack-dollar mx-3 my-auto" style={{ cursor: "pointer", color: "green" }}></i>


                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle navLinks navLinksArrowHide" style={{ backgroundColor: 'transparent', padding: '0' }} href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {imageData ? (
                      <img
                        src={imageData}
                        alt="Avatar"
                        className="avatar-image"
                      />
                    ) : (
                      <img src={dummy_user} alt="Default Avatar" className="avatar-image" />
                    )}
                  </a>
                  <div className="dropdown-menu user-profile-dropdown" style={{ left: '-13vh' }} aria-labelledby="navbarDropdownMenuLink">
                    <Link className="dropdown-item UserDropdown" onClick={onLogout}>Logout</Link>
                    <Link className="dropdown-item UserDropdown" to="/ChangePassword" >Change Password</Link>
                    <Link className="dropdown-item UserDropdown" to="/EditProfile" >Edit Profile</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default StudentNav;
