import React, { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import axiosInstance from "./axiosInstance";
import "./managecontentstyle/managecontent.css";
import { toast } from "react-toastify";
import "./managecontentstyle/buttonstyle.css";

const CreateCourseModule = () => {
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [moduleNo, setModuleNo] = useState("");

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    axiosInstance
      .post("/api/managecontent/getAllCourses")
      .then((response) => {
        const sortedCourses = response.data.slice().sort((a, b) => {
          const coursenameA = a.coursename;
          const coursenameB = b.coursename;
          return coursenameA.localeCompare(coursenameB);
        });

        setCourses(sortedCourses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!course || !name  || !moduleNo) {
      console.log("Please fill in all fields");
      return;
    }

    const courseid1 = courses.find((c) => c.coursename === course)?.Course_id;

    const payload = {
      Course_id: courseid1,
      modulename: name,
      ModuleDescription: description,
      ModuleNumber: moduleNo,
    };

    console.log(courseid1);
    axiosInstance
      .post("/api/managecontent/addModule", payload)
      .then(() => {
        console.log("Data has been sent to the server");
        setCourse("");
        setName("");
        setDescription("");
        setModuleNo("");
        toast.success("Module added successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log("Internal server error", error);
      });
  };

  return (
    <div className="create-course-module">
      <h2>Create Course Module</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="course-select">Course*</Label>
          <Input
            type="select"
            name="select"
            id="course-select"
            value={course}
            required
            onChange={(event) => setCourse(event.target.value)}
          >
            <option>Select a course</option>
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.coursename}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="name-input">Name*</Label>
          <Input
            type="text"
            name="name"
            id="name-input"
            placeholder="Enter name"
            value={name}
            required
            onChange={(event) => setName(event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="description-input">Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description-input"
            placeholder="Enter description"
            value={description}
            
            onChange={(event) => setDescription(event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="module-no-input">Module No*</Label>
          <input
            type="number"
            name="module-no"
            min="0"
            id="module-no-input"
            placeholder="Enter module number"
            value={moduleNo}
            required
            onChange={(event) => setModuleNo(event.target.value)}
          />
        </FormGroup>
        <div className="inz-button">
          <button type="submit" className="inz-save-button">
            Save
          </button>
        </div>
      </Form>
    </div>
  );
};

export default CreateCourseModule;
