import React, { useState, useEffect, useContext, useRef } from 'react';
import axiosInstance from './axiosInstance';
import './StudentFee.css';
import AdminNav from '../Navbars/AdminNav';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import logo from '../CourseImages/Logo.png';
import footer from "../CourseImages/footer.png";
import jsPDF from 'jspdf';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import axios from 'axios';
import html2canvas from 'html2canvas';
import { usePDF, Resolution } from 'react-to-pdf';

const InvoicePage = () => {


  const navigate = useNavigate();
  const { isAdmin, isTeacher, isStudent, isGuest, setUserRole, setUserInfo, userInfo } = useContext(UserContext);
  const [invoiceNumber, setInvoiceNumber] = useState(0)
  const [invoiceList, setInvoiceList] = useState([])
  const currentDate = new Date();
  let daysToExpire = 0;
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const bankData = {
    PKR: {
      onlineTransfer: {
        "Bank Name": "Bank Islami",
        "Account Holder": "Polymath-Kids (SMC-PRIVATE) LTD",
        "Account Number": "PK11BKIP0209300275370001"
      },
      mobileWallet: {
        "Type": "Jazz Cash",
        "Number": "03266699907",
        "Account Holder": "Maryam Hamad"
      }
    },
    USD: {
      onlineTransfer: {
        "Account Holder": "Maryam Hamad",
        "Routing Number": "026073008",
        "WireRouting Number": "026073008",
        "SWIFT": "CMFGUS33"
      }
    },
    AUD: {
      onlineTransfer: {
        "Account Holder": "Maryam Hamad",
        "BSB Code": "802-985",
        "Account Number": "114169499"
      }
    },
    Pounds: {
      onlineTransfer: {
        "Account Holder": "Maryam Hamad",
        "sort Code": "23-14-70",
        "Account Number": "11561223",
        "Account Number": "GB55 TRWI 2314 7011 5612 23"
      }
    },
    Euros: {
      onlineTransfer: {
        "Account Holder": "Maryam Hamad",
        "BIC": "TRWIBEB1XXX",
        "Account Number": "BE75 9676 0065 1951"
      }
    },
    UAE: {
      onlineTransfer: {
        "Bank Name": "Bank Islami",
        "Account Holder": "Polymath-Kids (SMC-PRIVATE) LTD",
        "Account Number": "PK11BKIP0209300275370001"
      },
    },  // No data provided
    Riyal: {
      onlineTransfer: {
        "Bank Name": "Bank Islami",
        "Account Holder": "Polymath-Kids (SMC-PRIVATE) LTD",
        "Account Number": "PK11BKIP0209300275370001"
      },
    },        // No data provided
    CanadianDollars: {
      onlineTransfer: {
        "Account Holder": "Maryam Hamad",
        "Institution Number": "621",
        "Account Number": "200110558833",
        "Transit Number": "16001"
      }
    }
  };

  const expiryDate = new Date(currentDate);
  expiryDate.setDate(currentDate.getDate() + parseInt(daysToExpire || 10));
  let formattedExpiryDate = expiryDate.toLocaleDateString('en-GB', options).replace(',', '');;


  const [invoiceData, setInvoiceData] = useState({
    student: '',
    course: '',
    currency: 'PKR',
    monthlyQuantity: 1,
    monthlyFee: 0,
    monthlyDiscount: 0,
    monthlyDiscountPercent: 0,
    membershipQuantity: 1,
    membershipFee: 0,
    membershipDiscount: 0,
    membershipDiscountPercent: 0,
    abacusQuantity: 1,
    abacusFee: 0,
    abacusDiscount: 0,
    abacusDiscountPercent: 0,
    outstandingPayment: false,
    deliveryCharges: false,
    bankDetails: `Bank Name: ${process.env.REACT_APP_Bank_Name} \nAccount holder: ${process.env.REACT_APP_Holder} \nLTDIBAN: ${process.env.REACT_APP_LTDIBAN}`,
    note: '',
    studentName: '',
    studentEmail: '',
    parentname: "",
    phonenumber: "",
    address: "",
    courseName: '',
    subtotal: 0,
    total: 0,
    totalPayable: 0,
    quantity: 0,
    note: "",
    transactionCharges: 0,
  });

  const [formData, setFormData] = useState({
    student: '',
    course: '',
    currency: 'PKR',
    monthlyQuantity: 1,
    monthlyFee: 0,
    monthlyDiscount: 0,
    monthlyDiscountPercent: 0,
    membershipQuantity: 1,
    membershipFee: 0,
    membershipDiscount: 0,
    membershipDiscountPercent: 0,
    abacusFee: 0,
    abacusQuantity: 1,
    abacusDiscount: 0,
    abacusDiscountPercent: 0,
    outstandingPayment: false,
    deliveryCharges: false,
    bankDetails: `Bank Name: ${process.env.REACT_APP_Bank_Name} \nAccount holder: ${process.env.REACT_APP_Holder} \nLTDIBAN: ${process.env.REACT_APP_LTDIBAN}`,
    note: '',
    transactionCharges: 0,
  });

  const [students, setStudents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [reloadData, setReloadData] = useState(false)
  const [discountList, setDiscountList] = useState([])
  const fetchCourses = async () => {
    try {
      if (formData?.student != "") {
        const response = await axiosInstance.get(`/coursesByStudentId/${formData?.student}`);
        const sortedCourses = response.data.slice().sort((a, b) => {
          return a.coursename.localeCompare(b.coursename);
        });
        setCourses(sortedCourses);

      }

    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };
  useEffect(() => {
    if (typeof process !== 'undefined' && process.env) {
      daysToExpire = process.env.REACT_APP_Fee_Due_Days || 12;
      formattedExpiryDate = expiryDate.toLocaleDateString('en-GB', options).replace(',', '');;
    }


    const fetchStudents = async () => {
      try {
        const response = await axiosInstance.get('/student');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/getDiscounts`).then((res) => {
      console.log("setDiscountList : ", res?.data?.discounts)
      setDiscountList(res?.data?.discounts)
    })

    // 
    fetchCourses();
    fetchStudents();
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/generateInvoiceNumber`).then((res) => {
      console.log("setInvoiceNumber : ", res?.data?.invoiceNumber)

      setInvoiceNumber(res?.data?.invoiceNumber)
    })
  }, []);


  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    })
    if (name == "student") { fetchCourses(); }
  };
  const handleChangeDiscount = async (e, discount, fee) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
      [discount]: (e.target.value / 100) * fee,
    })
  }
  useEffect(() => {
    handleSubmit()

  }, [formData])

  const handleSubmit = async () => {
    // e.preventDefault();

    const selectedStudent = students.find(student => student.User_id === parseInt(formData.student));

    const selectedCourse = courses.find(course => course.Course_id === parseInt(formData.course));

    // Ensure all values are numbers before performing calculations
    const monthlyFee = parseFloat(formData.monthlyFee) || 0;
    const membershipFee = parseFloat(formData.membershipFee) || 0;
    const abacusFee = parseFloat(formData.abacusFee) || 0;
    const monthlyDiscount = parseFloat(formData.monthlyDiscount) || 0;
    const membershipDiscount = parseFloat(formData.membershipDiscount) || 0;
    const abacusDiscount = parseFloat(formData.abacusDiscount) || 0;
    const outstandingPayment = parseFloat(formData.outstandingPayment) || 0;
    const deliveryCharges = parseFloat(formData.deliveryCharges) || 0;
    const monthlyQuantity = parseFloat(formData.monthlyQuantity) || 1;
    const membershipQuantity = parseFloat(formData.membershipQuantity) || 1;
    const abacusQuantity = parseFloat(formData.abacusQuantity) || 1;



    // Quantity
    const quantity = parseFloat(formData.Quantity) || 1;

    // Calculate total
    // const total = (monthlyFee - monthlyDiscount) * quantity
    //   + (membershipFee - membershipDiscount) * quantity
    //   + (abacusFee - abacusDiscount) * quantity
    //   + outstandingPayment
    //   + deliveryCharges;
    // const total = (monthlyFee - monthlyDiscount) * monthlyQuantity;
    const total = (monthlyFee - monthlyDiscount) * monthlyQuantity
      + (membershipFee - membershipDiscount) * membershipQuantity
      + (abacusFee - abacusDiscount) * abacusQuantity
      + outstandingPayment
      + deliveryCharges;
    const subtotal = (monthlyFee) * monthlyQuantity
      + (membershipFee) * membershipQuantity
      + (abacusFee) * abacusQuantity
      + outstandingPayment
      + deliveryCharges;

    const data = {
      ...formData,
      studentName: selectedStudent ? selectedStudent.firstname + " " + selectedStudent.lastname : '',
      studentEmail: selectedStudent ? selectedStudent.username : '',
      parentname: selectedStudent ? selectedStudent.parentname : "",
      phonenumber: selectedStudent ? selectedStudent.phonenumber : "",
      address: selectedStudent ? selectedStudent.address : "",
      courseName: selectedCourse ? selectedCourse.coursename : '',
      total,
      subtotal,
      totalPayable: total,
      quantity
    };

    setInvoiceData(data);

    // try {
    //   const response = await axiosInstance.post('/generate-invoice', data);
    //   console.log(response.data);
    // } catch (error) {
    //   console.error('Error generating invoice:', error);
    // }
  };
  async function onLogout() {
    await fetch(`${process.env.REACT_APP_LOCAL_SERVER_API}/logout`, {
      credentials: 'include',
      method: 'POST',
    });
    // setUserInfo(null);
    console.log("navigating");
    navigate('/');
    // console.log("navigated");
    // setUserRole('guest');
  }
  const username = userInfo?.username;
  const _avatar_ = userInfo?.avatar;
  const fetchInvoiceList = () => {
    axios.get(`${process.env.REACT_APP_LOCAL_SERVER_API}/allStudentsInvoiceDetails`)
    .then(response => {
      setInvoiceList(response?.data);
      console.log("setInvoiceList: ", response)
    })
    .catch(error => {
      console.error('Error fetching Invoices:', error);
    });
  }
  useEffect(() => {
    fetchInvoiceList()
  }, [reloadData])
  const downloadPDF = async (e) => {
    e.preventDefault()
    toPDF();
  }
  const sendEmail = async (e) => {

    try {
      e.preventDefault()
      const pdf = await toPDF();
      // const pdfBlob = await new Blob([pdfInstance.output("blob")], {
      //   type: "application/pdf",
      // });
      const pdfBlob = pdf.output('blob');


      // Create FormData and append the PDF
      const formDataInvoice = new FormData();
      // formDataInvoice.append('pdfFile', pdfBlob);
      formDataInvoice.append('pdfFile', pdfBlob, 'invoice.pdf');


      // Add any other data you need to send
      formDataInvoice.append('invoiceData', JSON.stringify(invoiceData));

      // formDataInvoice.append('invoiceData', invoiceData);

      const response = await axios.post(`${process.env.REACT_APP_LOCAL_SERVER_API}/generateInvoice`, formDataInvoice, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then(() => {
        setInvoiceNumber(invoiceNumber + 1)
        fetchInvoiceList()
      })
      console.log(response.data);
    } catch (error) {
      console.error('Error generating invoice:', error);
    }

  }
  async function changePaidStatus(value, fee_id) {
    // alert(value+"--"+fee_id)
    const response = await axios.get(
      `${process.env.REACT_APP_LOCAL_SERVER_API}/markPaymentDone/${fee_id}/1`
    ).then(() => {
      setReloadData(!reloadData)
    })

  }
  async function changeAmountPaid(value, fee_id) {
    // alert(value+"--"+fee_id)
    const response = await axios.get(
      `${process.env.REACT_APP_LOCAL_SERVER_API}/amountPaid/${fee_id}/${value}`
    ).then(() => {
      setReloadData(!reloadData)
    })

  }
  const { toPDF, targetRef } = usePDF({ filename: `invoice No ${invoiceNumber} - ${invoiceData?.studentName} -${invoiceData?.courseName} - ${new Date().toLocaleDateString('en-GB', options).replace(',', '')}.pdf`, resolution: Resolution.NORMAL });
  function formatDate(dateString = "") {
    if (dateString == "") {
      return ""
    }
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    return `${day} ${month}`;
  }

  return (
    <>
      <AdminNav username={username} avatar={_avatar_} onLogout={onLogout} />
      <div className="invoice-page">
        <form
          // onSubmit={handleSubmit}
          //  onChange={handleSubmit} 
          className="invoice-form">
          <div className="form-group">
            {/* <label>Choose a Student</label> */}
            <select name="student" value={formData.student} onChange={handleChange}>
              <option value="">Select Student</option>
              {students.map(student => (
                <option key={student.User_id} value={parseInt(student.User_id)}>{student.firstname} {student?.lastname}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            {/* <label>Choose a Course</label> */}
            <select name="course" value={formData.course} onChange={handleChange}>
              <option value="">Select Course</option>
              {courses.map(course => (
                <option key={course.Course_id} value={course.Course_id}>{course.coursename}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            {/* <label>Currency</label> */}
            <select name="currency" value={formData.currency} onChange={handleChange}>
              <option value="PKR">PKR</option>
              <option value="USD">USD</option>
              <option value="AUD">AUD </option>
              <option value="Pounds"> Pounds </option>
              <option value="Euros"> Euros </option>
              <option value="UAE"> UAE Dirhams </option>
              <option value="Riyal"> Riyal </option>
              <option value="CanadianDollars"> Canadian Dollars </option>
            </select>
          </div>

          <div className="fee-section">

            <div className="form-group monthly-fee">
              <label> <strong>Monthly Fee</strong></label>
              <div>
                <input
                  type="checkbox"
                  name="monthlyFee"
                  checked={formData.monthlyFee > 0}
                  onChange={handleChange}
                  style={{ width: "10%" }}

                />
                <input
                  type="number"
                  name="monthlyFee"
                  value={formData.monthlyFee}
                  onChange={handleChange}
                  placeholder="Fee"
                  style={{ width: "42%" }}
                />

                <input
                  type="number"
                  name="monthlyQuantity"
                  value={formData.monthlyQuantity}
                  onChange={handleChange}
                  placeholder="Quantity"
                  style={{ width: "38%", marginLeft: "5px" }}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <input
                  type="checkbox"
                  name="monthlyFee"
                  checked={formData.monthlyDiscount > 0}
                  // onChange={handleChange}
                  style={{ width: "10%" }}

                />
                <select name='monthlyDiscountPercent'
                  // onChange={handleChange}
                  onChange={(e) => handleChangeDiscount(e, "monthlyDiscount", invoiceData?.monthlyFee)}

                  style={{ width: "38%", marginRight: "5px" }}>
                  <option value={0}>Discount</option>
                  {
                    discountList && discountList?.map((item) => (
                      <option value={item?.discountpercentage}>
                        {item?.discountname}
                      </option>
                    ))
                  }
                </select>
                <input
                  type="number"
                  name="monthlyDiscount"
                  value={formData.monthlyDiscount}
                  // value={(invoiceData?.monthlyDiscountPercent/100)*invoiceData?.monthlyFee}
                  onChange={handleChange}
                  placeholder="Discount"
                  style={{ width: "38%" }}
                  disabled
                />
              </div>
              <div className="date-inputs" style={{ marginTop: "10px" }}>
                <div className="form-group">
                  {/* <label label>L1 Date</label> */}
                  <input placeholder='L1 Date'
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    type="text" name="l1Date" value={formData.l1Date} onChange={handleChange} />
                </div>
                <div className="form-group">
                  {/* <label>L2 Date</label> */}
                  <input placeholder='L2 Date' onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    type="text" name="l2Date" value={formData.l2Date} onChange={handleChange} />
                </div>
                <div className="form-group">
                  {/* <label>L3 Date</label> */}
                  <input placeholder='L3 Date' onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    type="text" name="l3Date" value={formData.l3Date} onChange={handleChange} />
                </div>
                <div className="form-group">
                  {/* <label>L4 Date</label> */}
                  <input placeholder='L4 Date' onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    type="text" name="l4Date" value={formData.l4Date} onChange={handleChange} />
                </div>
              </div>

            </div>
            <div className="form-group membership-fee">
              <label><strong>Membership Fee</strong></label>
              <div className="form-group ">
                <div>
                  <input
                    type="checkbox"
                    name="membershipFee"
                    checked={formData.membershipFee > 0}
                    onChange={handleChange}
                    style={{ width: "10%" }}
                  />

                  <input
                    type="number"
                    name="membershipFee"
                    value={formData.membershipFee}
                    onChange={handleChange}
                    placeholder="Fee"
                    style={{ width: "40%" }}
                  />
                  <input
                    type="number"
                    name="membershipQuantity"
                    value={formData.membershipQuantity}
                    onChange={handleChange}
                    placeholder="Quantity"
                    style={{ width: "38%", marginLeft: "5px" }}
                  />
                  <select name='membershipDiscountPercent'
                    // onChange={handleChange}
                    onChange={(e) => handleChangeDiscount(e, "membershipDiscount", invoiceData?.membershipFee)}
                    style={{ width: "38%", marginLeft: "10%" }}>
                    <option value={0}>Discount</option>
                    {
                      discountList && discountList?.map((item) => (
                        <option value={item?.discountpercentage}>
                          {item?.discountname}
                        </option>
                      ))
                    }
                  </select>
                  <input
                    type="number"
                    name="membershipDiscount"
                    value={formData.membershipDiscount}
                    onChange={handleChange}
                    placeholder="Discount"
                    style={{ width: "40%", marginLeft: "10px", marginTop: "10px" }}
                    disabled
                  />
                </div>

              </div>
            </div>

            <div className="form-group abacus-fee">
              <label><strong>Soroban Abacus Fee</strong></label>
              <div>
                <input
                  type="checkbox"
                  name="abacusFee"
                  checked={formData.abacusFee > 0}
                  onChange={handleChange}
                  style={{ width: "10%" }}
                />

                <input
                  type="number"
                  name="abacusFee"
                  value={formData.abacusFee}
                  onChange={handleChange}
                  placeholder="Fee"
                  style={{ width: "40%" }}
                />
                <input
                  type="number"
                  name="abacusQuantity"
                  value={formData.abacusQuantity}
                  onChange={handleChange}
                  placeholder="Quantity"
                  style={{ width: "40%", marginLeft: "5px" }}
                />

                <select
                  name='abacusDiscountPercent'
                  onChange={(e) => handleChangeDiscount(e, "abacusDiscount", invoiceData?.abacusFee)}
                  style={{ width: "40%", marginLeft: "10%" }}
                >
                  <option value={0}>Discount</option>
                  {
                    discountList && discountList?.map((item) => (
                      <option
                        value={item?.discountpercentage}>
                        {item?.discountname}
                      </option>
                    ))
                  }
                </select>
                <input
                  type="number"
                  name="abacusDiscount"
                  value={formData.abacusDiscount}
                  onChange={handleChange}
                  placeholder="Discount"
                  style={{ width: "40%", marginLeft: "5px", marginTop: "10px" }}
                  disabled
                />

              </div>
            </div>


            <div className="form-group outstanding-fee">
              <label><strong>Outstanding Payment</strong></label>
              <div>
                <input
                  type="checkbox"
                  name="outstandingPayment"
                  checked={formData.outstandingPayment}
                  onChange={handleChange}
                  style={{ width: "10%" }}
                />

                <input
                  type="number"
                  name="outstandingPayment"
                  value={formData.outstandingPayment}
                  onChange={handleChange}
                  placeholder="Outstanding Payment"
                  style={{ width: "90%" }}
                />
              </div>

            </div>
            <div className="form-group delivery-fee">
              <label><strong>Delivery Charges</strong></label>
              <div>
                <input
                  type="checkbox"
                  name="deliveryCharges"
                  checked={formData.deliveryCharges}
                  onChange={handleChange}
                  style={{ width: "10%" }}
                />

                <input
                  type="number"
                  name="deliveryCharges"
                  value={formData.deliveryCharges}
                  onChange={handleChange}
                  placeholder="Delivery Charges"
                  style={{ width: "90%" }}
                />

              </div>
            </div>
            <div className="form-group outstanding-fee">
              <label><strong>Transaction Charges %</strong></label>
              <div>
                <input
                  type="checkbox"
                  name="transactionCharges"
                  checked={formData.transactionCharges}
                  onChange={handleChange}
                  style={{ width: "10%" }}
                />

                <input
                  type="number"
                  name="transactionCharges"
                  value={formData.transactionCharges}
                  onChange={handleChange}
                  placeholder="Transaction Charges %"
                  style={{ width: "90%" }}
                />

              </div>
            </div>

          </div>

          <div className="form-group bank-details">
            <label><strong>Bank Details</strong></label>
            <textarea
              name="bankDetails"
              value={formData.bankDetails}
              onChange={handleChange}
              placeholder="Enter bank details here"
              rows={6}
              style={{ height: "150px" }}
            ></textarea>
          </div>

          <div className="form-group">
            {/* <label>Note</label> */}
            <textarea
              name="note"
              value={formData.note}
              onChange={handleChange}
              placeholder="Type note here..."></textarea>
          </div>
          {/* <button onClick={() => toPDF()}>Download PDF</button> */}

          <button onClick={(e) => downloadPDF(e)}>Generate Fee & PDF</button>
          <button onClick={(e) => sendEmail(e)}>Send Email</button>
        </form>

        {invoiceData && (
          <div className="invoice-display" id='invoice-display' ref={targetRef} >
            <div className="invoice-header">
              <img src={logo} height={100}></img>
              <p>Invoice No: {invoiceNumber}</p>
            </div>

            <h2>INVOICE</h2>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }} >
                <p><strong>Child Name:</strong> {invoiceData?.studentName}</p>
                <p><strong>Course Name:</strong> {invoiceData?.courseName}</p>
                <p><strong>Invoice Date:</strong> {new Date().toLocaleDateString('en-GB', options).replace(',', '')}</p>
              </div>
              <div style={{ display: "flex", alignItems: "end", height: "full" }}>
                <p><strong>Due Date:</strong> {formattedExpiryDate.slice(0, 15)}</p>

              </div>
            </div>
            <br />
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }} >
                <p><strong>Billed to:</strong> </p>
                <div className='billed'>
                  <p>{invoiceData?.parentname}</p>
                  <p>{invoiceData?.phonenumber}</p>
                  <p>{invoiceData?.studentEmail}</p>
                  <p>{invoiceData?.address}</p>
                </div>
              </div>
              <div style={{ width: "50%" }} >
                <p><strong>Billed From:</strong> </p>
                <div className='billed'>
                  <p>Polymath-Kids.com</p>
                  <p>+92 3266699907</p>
                  <p>info@polymath-kids.com</p>
                </div>
              </div>
            </div>


            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  {/* <th>Discount</th> */}
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {/* Monthly Fee */}
                {invoiceData?.monthlyFee > 0 && (
                  <tr>
                    <td>{`Membership Fee (4 Lesson )`}<br />

                      {formatDate(formData?.l1Date)}{formData.l1Date ? "," : ""} {formatDate(formData?.l2Date)}{formData.l2Date ? "," : ""} {formatDate(formData?.l3Date)}{formData.l4Date ? "," : ""} {formatDate(formData?.l4Date)}
                    </td>
                    <td>{invoiceData?.monthlyQuantity}</td>
                    <td>{invoiceData?.currency} {invoiceData?.monthlyFee}</td>
                    <td>{invoiceData?.currency} {(invoiceData?.monthlyFee - invoiceData?.monthlyDiscount) * invoiceData?.monthlyQuantity}</td>
                  </tr>
                )}

                {/* Membership Fee */}
                {invoiceData?.membershipFee > 0 && (
                  <tr>
                    <td>{`Membership Fee (4 Lesson )`}</td>
                    <td>{invoiceData?.membershipQuantity}</td>
                    <td>{invoiceData?.currency} {invoiceData?.membershipFee}</td>
                    <td>{invoiceData?.currency} {(invoiceData?.membershipFee - invoiceData?.membershipDiscount) * invoiceData?.membershipQuantity}</td>
                  </tr>
                )}

                {/* Abacus Fee */}
                {invoiceData?.abacusFee > 0 && (
                  <tr>
                    <td>Abacus Fee</td>
                    <td>{invoiceData?.abacusQuantity}</td>
                    <td>{invoiceData?.currency} {invoiceData?.abacusFee}</td>
                    <td>{invoiceData?.currency} {(invoiceData?.abacusFee - invoiceData?.abacusDiscount) * invoiceData?.abacusQuantity}</td>
                  </tr>
                )}

                {/* Outstanding Payment */}
                {invoiceData?.outstandingPayment > 0 && (
                  <tr>
                    <td>Outstanding Payment</td>
                    <td></td>
                    <td></td>
                    {/* <td></td> */}
                    <td>{invoiceData?.currency} {invoiceData?.outstandingPayment}</td>
                  </tr>
                )}

                {/* Delivery Charges */}
                {invoiceData?.deliveryCharges > 0 && (
                  <tr>
                    <td>Delivery Charges</td>
                    <td></td>
                    <td></td>
                    {/* <td></td> */}
                    <td>{invoiceData?.currency} {invoiceData?.deliveryCharges}</td>
                  </tr>
                )}
                <tr style={{}}>
                  <td></td>
                  <td></td>
                  <td style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>Subtotal: </td>
                  <td style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>{invoiceData?.currency} {invoiceData?.subtotal}</td>

                </tr>
                <tr style={{}}>
                  <td style={{ fontSize: "15px", fontWeight: "bold", color: "#333" }}>Discounts:</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                {invoiceData?.monthlyFee > 0 && invoiceData?.monthlyDiscountPercent > 0 && (
                  <tr>
                    <td>Monthly Fee discount</td>
                    <td></td>
                    <td>{invoiceData?.monthlyDiscountPercent}%</td>
                    {/* <td></td> */}
                    <td>{invoiceData?.currency} {invoiceData?.monthlyDiscount}</td>
                  </tr>
                )}

                {invoiceData?.abacusFee > 0 && invoiceData?.abacusDiscountPercent > 0 && (
                  <tr>
                    <td>Abacus Fee discount</td>
                    <td></td>
                    <td>{invoiceData?.abacusDiscountPercent}%</td>
                    {/* <td></td> */}
                    <td>{invoiceData?.currency} {invoiceData?.abacusDiscount}</td>
                  </tr>
                )}

                {invoiceData?.membershipFee > 0 && invoiceData?.membershipDiscountPercent > 0 && (
                  <tr>
                    <td>Membership Fee discount</td>
                    <td></td>
                    <td>{invoiceData?.membershipDiscountPercent}%</td>
                    {/* <td></td> */}
                    <td>{invoiceData?.currency} {invoiceData?.membershipDiscount}</td>
                  </tr>
                )}
                <tr style={{ fontWeight: "bold", fontSize: "16px" }}>
                  <td></td>
                  <td></td>
                  <td>Total Discount:</td>
                  <td>{invoiceData?.currency} ({invoiceData?.subtotal - invoiceData?.totalPayable})</td>
                </tr>
                <tr style={{ fontWeight: "bold", fontSize: "14px" }}>
                  <td></td>
                  <td></td>
                  <td>Transaction Charges:</td>
                  <td>{invoiceData?.currency} {invoiceData?.totalPayable * (invoiceData?.transactionCharges / 100)} ({invoiceData?.transactionCharges}%)</td>
                </tr>
                <tr className="total">
                  <td></td>
                  <td></td>
                  <td>Total Payable:</td>
                  <td>{invoiceData?.currency} {invoiceData?.totalPayable + (invoiceData?.totalPayable * (invoiceData?.transactionCharges / 100))}</td>
                </tr>
              </tbody>
            </table>

            {/* <p> <strong>Discounts:</strong> </p> */}
            {/* <p className='grayedColor'>{invoiceData?.monthlyFee > 0 && `Monthly Fee discount@${invoiceData?.monthlyDiscountPercent}% : ${invoiceData?.currency} ${invoiceData?.monthlyDiscount}`}</p> */}
            {/* <p className='grayedColor'>{invoiceData?.abacusFee > 0 && `Abacus Fee discount@${invoiceData?.abacusDiscountPercent}% : ${invoiceData?.currency} ${invoiceData?.abacusDiscount}`}</p> */}
            {/* <p className='grayedColor'>{invoiceData?.membershipFee > 0 && `Membership Fee discount@${invoiceData?.membershipDiscountPercent}% : ${invoiceData?.currency} ${invoiceData?.membershipDiscount}`}</p> */}

            {/* Subtotal */}
            {/* <p className="subtotal">Subtotal: {invoiceData?.currency} {invoiceData?.subtotal}</p> */}
            {/* <p className="total">Total Payable: {invoiceData?.currency} {invoiceData?.totalPayable}</p> */}

            {/* Payment Method */}
            <p><strong>Payment Method:</strong></p>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <p><strong>1. Online Account Transfer</strong></p>
              </div>
              {/* <ul> */}
              <div style={{ width: "50%" }}>
                {bankData?.[invoiceData.currency]?.onlineTransfer && Object?.entries(bankData?.[invoiceData.currency]?.onlineTransfer)?.map(([key, value]) => (
                  <p key={key} className="text-gray-600">
                    {/* Convert camelCase to Title Case */}
                    {key.replace(/^./, str => str.toUpperCase())}: {value}
                  </p>
                ))}
                {/* <p>Bank Name : {bankData[invoiceData.currency] &&
                  bankData[invoiceData.currency].onlineTransfer ?
                  bankData[invoiceData.currency].onlineTransfer.bankName : 'N/A'}</p>
                <p>Account Holder : {bankData[invoiceData.currency] &&
                  bankData[invoiceData.currency].onlineTransfer ?
                  bankData[invoiceData?.currency]?.onlineTransfer?.accountHolder : "N/A"} LTD</p>
                <p>IBAN : {bankData[invoiceData.currency] &&
                  bankData[invoiceData.currency].onlineTransfer ?
                  bankData[invoiceData?.currency]?.onlineTransfer?.accountNumber : "N/A"}</p> */}

              </div>
              {/* </ul> */}
            </div>
            <div style={{ display: "flex", margin: "20px 0" }}>
              {bankData?.[invoiceData.currency]?.mobileWallet && <div style={{ width: "50%" }}>
                <p><strong>2.  Mobile Wallet</strong></p>
              </div>}
              {/* <ul> */}
              <div style={{ width: "50%" }}>
                {bankData?.[invoiceData.currency]?.mobileWallet && Object.entries(bankData?.[invoiceData.currency]?.mobileWallet)?.map(([key, value]) => (
                  <p key={key} className="text-gray-600">
                    {/* Convert camelCase to Title Case */}
                    {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}: {value}
                  </p>
                ))}
                {/* <p>Type: {bankData[invoiceData.currency] &&
                  bankData[invoiceData.currency].mobileWallet ?
                  bankData[invoiceData?.currency]?.mobileWallet?.type : "N/A"}</p>
                <p>Number: {bankData[invoiceData.currency] &&
                  bankData[invoiceData.currency].mobileWallet ?
                  bankData[invoiceData?.currency]?.mobileWallet?.number : "N/A"}</p>
                <p>Account Holder Name: {bankData[invoiceData.currency] &&
                  bankData[invoiceData.currency].mobileWallet ?
                  bankData[invoiceData?.currency]?.mobileWallet?.accountHolder : "N/A"}</p> */}

              </div>
              {/* </ul> */}
            </div>
            {invoiceData?.note && <div>Note : {invoiceData?.note}</div>}

            {/* Total Payable */}
            <img src={footer} height={400} style={{ width: "100%" }}></img>
            {/* <button onClick={downloadPDF}>Download PDF</button> */}

          </div>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "center", backgroundColor: "transparent" }}>
        <div className="invoice-list-display" style={{ backgroundColor: "transparent", boxShadow: "none" }}>
          <table>
            <thead>
              <tr>
                <th>Paid</th>
                <th>
                  Invoice No.</th>
                <th>Student Name</th>
                <th>Course</th>
                <th>Invoice Date</th>
                <th>Amount Due</th>
                <th>Due Date</th>
                <th>Amount Paid</th>
                <th>Paid on mm/dd/yyyy</th>
                <th>Receipt
                </th>

              </tr>
            </thead>
            <tbody>
              {
                invoiceList?.map((item) => (
                  <tr>
                    <td>
                      <input type='checkbox' defaultChecked={item?.paid} onChange={(e) => changePaidStatus(e.target.value, item.Fee_id)}></input>
                    </td>
                    <td><a className='invoiceLink' href={process.env.REACT_APP_Invoice_Path + item?.InvoiceFile} target="_blank">Invoice No  {item?.Fee_id}</a></td>
                    <td>{item?.username}</td>
                    <td className={item?.paid ? "" : "unpaid"}>{item?.coursename}</td>
                    <td className={item?.paid ? "" : "unpaid"}>{item?.generationdate}</td>
                    <td className={item?.paid ? "" : "unpaid"}>{item?.amountdue}</td>
                    <td className={item?.paid ? "" : "unpaid"}>{item?.duedate}</td>
                    <td><input type='number' placeholder={item?.amountdue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter")
                          changeAmountPaid(e.target.value, item.Fee_id)
                      }}
                    // onChange={(e)=>changeAmountPaid(e.target.value,item.Fee_id)} 
                    /></td>
                    <td><input type='date' /></td>
                    <td></td>

                  </tr>
                ))
              }

            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default InvoicePage;
